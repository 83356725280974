@import "../../../css/variables.scss";

.mri-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000;
  .modal-content {
    position: fixed;
    background: $light-gray;
    overflow-y: scroll;
    height: 550px;
    width: 950px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 40px 30px 50px;
    @include respond-to(mobile) {
      width: 90%;
      height: 70%;
      padding: 50px 10px 30px;
    }
    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond-to(mobile) {
        flex-direction: column;
        align-items: flex-start;
      }
      .title-bar {
        font-size: 30px;
        margin: 0;
        @include respond-to(mobile) {
          font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
          font-size: 18px;
          margin-bottom: 38px;
        }
      }
    }
    .title {
      font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
      text-align: center;
      text-transform: capitalize;
    }
    .mri-scan-container {
      display: flex;
      @include respond-to(mobile) {
        flex-direction: column;
      }
      .image-container {
        display: flex;
        flex-direction: column;
        margin-right: 35px;
        width: 50%;
        height: 100%;
        @include respond-to(mobile) {
          width: 100%;
        }
        &:last-of-type {
          margin: 0;
        }
        img {
          width: 100%;
          height: auto;
        }
        .image-label-container {
          background-color: $dark-blue;
          display: flex;
          justify-content: space-around;
          padding: 10px;
          .image-label {
            color: $white;
            font-family: "ApexSans-Book", 'Helvetica Neue', Roboto, Arial, sans-serif;
          }
        }
        .measurement-container {
          background-color: $border-gray-alt;
          display: flex;
          height: 50px;
          justify-content: space-around;
          padding: 10px;
          .transverse-contianer {
            flex-direction: column;
            .kidney-measurements {
              display: block;
            }
          }
        }
      }
    }
    .modal-footer {
      margin-top: 30px;
    }
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 33px;
    height: 33px;
    opacity: 0.3;
    cursor: pointer;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before,
  .close:after {
    position: absolute;
    content: " ";
    left: 15px;
    height: 34px;
    width: 2px;
    background-color: $border-gray;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}
