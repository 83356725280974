@import "./variables.scss";

.resources-homepage {
  font-size: 16px;
  margin: 0 auto;
  width: 938px;
  @include respond-to(mobile) {
    width: 100%;
    padding: 0 15px 40px;
    margin: 0;
    box-sizing: border-box;
    .tabbed-top {
      margin-top: 0;
    }
  }
  .page-crumb {
    display: none;
    @include respond-to(mobile) {
      display: block;
    }
  }
  .title {
    font-size: 36px;
    font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
      "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
    @include respond-to(mobile) {
      font-size: 30px;
    }
  }
  .hero {
    display: flex;
    justify-content: space-evenly;
    margin: 0 0 100px;
    @include respond-to(mobile) {
      flex-direction: column;
      margin-bottom: 50px;
      .media-description {
        .title {
          font-size: 22px;
          font-weight: normal;
          font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
          margin: 0;
        }
        .subtitle {
          font-size: 18px;
          font-weight: normal;
          margin: 0;
        }
        .description {
          display: block;
          font-size: 15px;
          margin: 10px 0;
        }
      }
    }
    .media-container {
      margin-right: 30px;
      @include respond-to(mobile) {
        margin-right: 0;
      }
      video,
      img {
        border: 1px solid $black;
        width: 480px;
        @include respond-to(mobile) {
          width: 100%;
        }
      }
    }
  }
  .row {
    display: flex;
    margin-top: 50px;
    @include respond-to(mobile) {
      margin-bottom: 50px;
    }
    .media-container {
      margin-right: 30px;
      @include respond-to(mobile) {
        width: 25%;
        min-width: 100px;
      }
      video,
      img {
        border: 1px solid $black;
        width: 330px;
        @include respond-to(mobile) {
          width: 100%;
        }
      }
    }
  }
  .media-description {
    @include respond-to(mobile) {
      width: 75%;
    }
    .title {
      font-size: 22px;
      font-weight: normal;
      margin: 0;
      @include respond-to(mobile) {
        font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
      }
    }
    .subtitle {
      font-size: 18px;
      font-weight: normal;
      margin: 0;
      @include respond-to(mobile) {
        font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
      }
    }
    .description {
      font-size: 15px;
      margin: 10px 0;
      @include respond-to(mobile) {
        display: none;
        &.last {
          display: block;
        }
      }
    }
    .link-container {
      color: $blue;
      font-size: 15px;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
        "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
      @include respond-to(mobile) {
        display: flex;
        margin: 15px 0;
      }
      .video-link {
        cursor: pointer;
        border-right: 2px solid $border-gray;
        padding-right: 5px;
        margin-right: 5px;
        &.last {
          border-right: none;
        }
      }
      .pdf-link {
        color: $blue;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
  .pdf-page {
    canvas {
      height: unset !important;
      width: unset !important;
      max-width: 100%;
    }
  }
  .pdf-loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100000;
    display: grid;
    place-items: center;
    p {
      background: white;
      padding: 4rem;
    }
  }
  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100000;
    .modal-content {
      position: fixed;
      background: transparent;
      width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      video {
        width: 100%;
      }
      .close {
        position: absolute;
        right: -15px;
        top: -15px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-color: $black;
        z-index: 1;
        border-radius: 50%;
        padding: 5px;
        border: 3px solid $border-gray;
      }
      .close:before,
      .close:after {
        position: absolute;
        content: " ";
        left: 49%;
        height: 20px;
        width: 2px;
        background-color: $white;
      }
      .close:hover:before,
      .close:hover:after {
        background-color: #555555;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
    }
  }
  .pkd-page {
    display: flex;
    margin-top: 70px;
    padding: 50px 0;
    @include respond-to(mobile) {
      margin-top: 30px;
    }
    .information {
      margin: 0 0 0 50px;
      @include respond-to(mobile) {
        margin: 0;
        width: 100%;
      }
      h2 {
        font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
        font-size: 20px;
        font-weight: normal;
        margin-top: 0;
      }
      p {
        margin-top: 0;
      }
    }
    a {
      text-decoration: none;
    }
    hr {
      margin: 30px 0;
      border-style: solid;
      border-bottom: none;
      color: $gray;
    }
    .button {
      align-items: center;
      border: none;
      border-radius: 5px;
      color: $white;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
        "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
      height: 40px;
      justify-content: center;
      margin-top: 30px;
      width: 220px;
      &.large {
        width: 320px;
      }
      &.confirm {
        background-color: $blue;
      }
    }
    .image-container {
      @include respond-to(mobile) {
        display: none;
      }
      img {
        width: 340px;
        @include respond-to(mobile) {
          width: 260px;
        }
      }
    }
  }
  @include respond-to(mobile) {
    .tabbed-top {
      height: inherit;
      .tab-nav {
        align-items: flex-start;
        flex-direction: column;
        margin: 0;
        width: 100%;
        .tab {
          border-top: 1px solid $tabbed-gray;
          border-left: none;
          padding: 0 4px;
          width: 100%;
          &:last-of-type {
            border-bottom: 1px solid $tabbed-gray;
            border-right: none;
          }
        }
      }
    }
  }
}
