@import "../../../css/variables.scss";

.egfr-levels {
    height: 100%;
    width: 100%;

    display: flex;
    align-content: center;
    align-items: center;
    gap: 1rem;

    flex-direction: row;
    @include respond-to(mobile) {
        flex-direction: column;
    }
}
