@import "./variables.scss";
@import "./dfn.scss";
@import "./sup.scss";
@import "./lists.scss";

@font-face {
  font-family: "ApexSans-Bold";
  font-display: fallback;
  src: url(../fonts/ApexSans-Bold.otf);
}
@font-face {
  font-family: "ApexSans-Book";
  font-display: fallback;
  src: url(../fonts/ApexSans-Book.otf);
}
@font-face {
  font-family: "ApexSans-Medium";
  font-display: fallback;
  src: url(../fonts/ApexSans-Medium.ttf);
}
html,
body {
  height: 100%;
  -ms-overflow-style: none;
}
body {
  cursor: default;
  margin: 0;
  padding: 0;
  font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  counter-reset: step;
}
input::-webkit-input-placeholder {
  font-size: 15px;
  font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
    "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
  color: #bfbfbf;
}

video::-webkit-media-controls [pseudo="-internal-media-controls-overflow-button"],
video::-webkit-media-controls [pseudo="-internal-media-controls-overflow-menu-list"] {
  display: none !important;
}
strong {
  font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
    "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
p > sup {
  font-size: 50%;
}

.disclaimer {
  font-size: 15px;
  max-width: 740px;
  margin: 45px auto;
  text-align: center;
}

.references {
  font-size: 11px;
  color: $dark-gray;
  margin-bottom: 40px;
  p {
    margin: 5px 0;
    color: $medium-gray;
  }
}
@include respond-to(mobile) {
  br {
    display: none;
  }
}

div.noscript {
  background-color: #3a4651;
  color: #ffc857;
  color: #3a4651;
  background-color: #ffc857;
  text-align: center;
  font-weight: bold;
  padding: 14px 0;
}
