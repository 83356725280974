@import "../css/variables.scss";

.grid-page-layout {
    width: 100%;
    display: grid;
    grid-template-columns:
        1fr
        80%
        1fr;

    & > * {
        grid-column: 2;
    }

    & > [data-full-bleed="true"] {
        width: 100%;
        grid-column: 1 / 4;
    }

    background-image: url(../images/grid-bg.png);

    justify-content: center;

    .preliminary-info {
        margin-top: 40px;
        padding-bottom: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: flex-start;

        @include respond-to(mobile) {
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
        .main-image {
            margin-right: 50px;
            @include respond-to(mobile) {
                width: 125px;
                margin: 20px auto 0;
            }
        }
        .title {
            font-size: 36px;
            @include respond-to(mobile) {
                font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                    "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
                font-size: 28px;
                text-align: center;
            }
        }
        .sub-header {
            font-size: 24px;
            @include respond-to(mobile) {
                font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
                font-size: 18px;
                text-align: center;
            }
        }
    }

    .card-container {
        padding-top: 30px;

        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;

        @include respond-to(mobile) {
            flex-direction: column;
            align-items: center;
        }
        .link-reset {
            min-height: 40vh;
            height: 100%;
            width: 100%;
            color: inherit;
            text-decoration: none;

            &:hover {
                .link-text {
                    text-decoration: underline;
                }
            }
            .card {
                &:hover {
                    filter: invert(15%);
                }

                display: flex;
                align-items: center;
                justify-content: center;
                // width: 280px;
                width: 100%;
                height: 100%;

                &[data-variant="expert"] {
                    flex-direction: row;
                    background-color: $dark-blue;
                    color: $white;
                    svg {
                        padding: 25px;

                        path {
                            fill: $blue;
                        }
                    }
                }
                &[data-variant="patient"] {
                    flex-direction: row;
                    background-color: $blue;
                    color: $white;

                    svg {
                        padding: 25px;

                        path {
                            fill: $dark-blue;
                        }
                    }
                }

                span {
                    display: inherit;
                    font-size: 20px;
                    text-transform: uppercase;
                    &.link-text {
                        &[data-variant="expert"] {
                            color: $blue;
                            .arrow {
                                border-left: 7px solid $blue;
                            }
                        }
                        &[data-variant="patient"] {
                            color: $dark-blue;

                            .arrow {
                                border-left: 7px solid $dark-blue;
                            }
                        }

                        font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue",
                            Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
                        margin-top: 25px;
                        .arrow {
                            display: inline-block;
                            width: 0;
                            height: 0;
                            margin-left: 4px;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-left: 7px solid $blue;
                        }
                    }
                }
                h2 {
                    font-size: 26px;
                    margin: 0;
                    line-height: 25px;
                    text-transform: uppercase;
                }
                p {
                    font-size: 15px;
                    margin: 0;
                }
            }
        }
    }

    .banner {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $light-gray;
        padding: 40px 0;
        width: 100%;
        @include respond-to(mobile) {
            margin: 60px 0 0;
            padding: 30px 20px;
        }
        svg {
            margin-bottom: 10px;
        }
        p {
            font-size: 18px;
            margin: 0;
            text-align: center;
            max-width: 940px;
        }
    }
    .disclaimer {
        margin-top: 8rem;
        margin-bottom: 8rem;
        width: 100%;
        grid-column: 1 / 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        text-align: center;

        p {
            max-width: 740px;
        }
    }
    &.not-found {
        .title {
            font-size: 42px;
            text-transform: uppercase;
            @include respond-to(mobile) {
                font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                    "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
                font-size: 28px;
                text-align: center;
            }
        }
        p {
            font-size: 18px;
            font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
        }
    }
}
