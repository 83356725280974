.slider-container {
  position: relative;
  top: 38px;
}

.slider-background {
  position: absolute;
  top: -10px;
  right: 0;
  background-color: gray;
  height: 10px;
}

.slider-background-progress {
  position: absolute;
  top: -10px;
  background-color: #3a4651;
  height: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slider-background-progress div.history {
  flex: 1;
  background-color: #3a4651;
  height: 10px;
}

.slider-background-progress div.tracked {
  flex: 1;
  background-color: #66b6ca;
  height: 10px;
}

.tick-marks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: -10px;
}

.tick-mark {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.tick-mark .tick-divider {
  height: 10px;
  width: 2px;
  background-color: #d5d6d5;
}

.tick-mark .tick-index {
  position: relative;
  margin-left: -0.5rem;
  left: 0.3rem;
}
