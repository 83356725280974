@import "../../css/variables.scss";

.patient-header {
  .header {
    border-bottom: 1px solid #979797;
    display: flex;
    padding-bottom: 20px;
    img {
      width: 229px;
      margin-right: 30px;
    }
    h1 {
      color: #4a4a4a;
      font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
      font-size: 36px;
      margin-top: 0;
    }
    .patient-summary {
      font-size: 18px;
      font-family: "ApexSans-Book", 'Helvetica Neue', Roboto, Arial, sans-serif;
      sup {
        font-size: 10px;
      }
    }
    ul {
      display: flex;
      padding: 0;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
        height: 50px;
      }
    }
  }

  .cKey {
    font-size: 12px;
    color: #4a4a4a;
    text-align: center;
    text-transform: uppercase;
    width: initial;
  }

  .cVal {
    font-size: 17px;
    font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
    display: flex;
    align-items: center;
    @include respond-to(desktop) {
      &.large {
        font-size: 36px;
      }
    }
  }

  .cVal span:nth-child(3) {
    color: #5ec0cd;
  }

  .cVal span span {
    font-weight: normal;
    font-family: "ApexSans-Book", 'Helvetica Neue', Roboto, Arial, sans-serif;
  }

  .superscript {
    font-size: 0.5rem;
  }

  .cVal .expand-button-arrows {
    margin: 0 0 0 2px;
    border: 1px solid black;
    background-color: white;
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 1px;
  }

  .cVal.spread {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .profile-acronyms {
    position: absolute;
    left: 31px;
    bottom: 42px;
    text-align: left;

    font-size: 9px;
    letter-spacing: 0.1px;
    color: #58595b;
  }

  .btn-references-container {
    position: absolute;
    left: 132px;
    bottom: 6px;
    width: 278px;
    text-align: center;
  }

  .btn-references {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 0 auto 0 auto;
  }
}
