@import "../css/variables.scss";

.card-435320b5-7c6c-47c3-8460-804389f4ffbd {
    margin-top: 120px;
    color: white;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    text-align: center;

    width: 100%;
    height: 100%;

    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    &[data-hoverable] {
        &:hover {
            filter: drop-shadow(16px 16px 20px $blue) invert(15%);
            cursor: pointer;
        }
    }

    .icon-container {
        width: 100%;

        img[class="icon"] {
            background-color: white;
            transform: translateY(-50%);
            margin-bottom: -80px;
            aspect-ratio: 1;
            object-fit: scale-down;
            border-radius: 50%;
            border: 5px solid var(--ring-color);
            width: 200px;
            height: 200px;
        }

        .caption {
            font-size: 18px;
            font-weight: bold;
        }
    }

    figure[class="hero-figure"] {
        width: 100%;
        padding: 0;
        margin: 0;
        img {
            width: clamp(200px, 100%, 300px);
        }
        figcaption {
            height: 60px;
            padding: 1rem;
        }
    }
}
