@import "./variables.scss";

.diagnosis-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  .modal-content {
    position: fixed;
    background: $white;
    overflow-y: auto;
    display: flex;
    box-sizing: border-box;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;
    width: 90%;
    height: 85%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem 2rem;
    img {
      width: 100%;
    }
    .glossary {
      font-size: 11px;
    }
    .references {
      font-size: 11px;
      // margin-top: 30px;
      color: #4a4a4a;
      // margin-bottom: 40px;
    }
    .references p {
      margin: 5px 0;
      color: #707070;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 33px;
      height: 33px;
      opacity: 0.3;
      cursor: pointer;
    }
    .close:hover {
      opacity: 1;
    }
    .close:before,
    .close:after {
      position: absolute;
      content: " ";
      left: 15px;
      height: 34px;
      width: 2px;
      background-color: $border-gray;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }
  }
}
