@import "./variables.scss";

.practical-guide {
    color: $dark-gray;
    font-size: 14px;
    width: 938px;
    margin: 0 auto;
    @include respond-to(mobile) {
        width: initial;
        padding: 0 10px;
    }
    h1 {
        font-size: 36px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        @include respond-to(mobile) {
            font-size: 30px;
        }
    }
    .description {
        font-size: 16px;
        @include respond-to(mobile) {
            font-size: 15px;
            sup {
                font-size: 10px;
            }
        }
    }
    h3 {
        text-align: center;
        font-size: 24px;
        span {
            font-size: 19px;
            font-weight: normal;
        }
    }
    img {
        width: 100%;
    }
    section {
        width: 800px;
        margin: auto;
        .title {
            font-size: larger;
            background: $blue;
            font-weight: bold;
            color: white;
            padding: 0.5rem 1rem;
            margin-bottom: 0;
            border-radius: 4px 4px 0 0;
        }
        .content {
            background: $light-blue;
            padding: 0.5rem 1rem;
            margin-top: 0;
            border-radius: 0 0 4px 4px;
        }
    }

    .footnote {
        font-size: 12px;
        &.mri {
            margin-top: 20px;
        }
    }

    .footer-section {
        margin-top: 60px;
        .cross-link-container {
            margin-top: 0;
        }
        .title {
            font-size: 16px;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            margin: 20px 0 0;
        }
        .references {
            font-size: 11px;
            margin-top: 30px;
            .title {
                display: block;
                font-size: 14px;
            }
            p {
                margin: 5px 0;
            }
        }
        .text-link {
            cursor: pointer;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        }
        div.disclaimer {
            font-size: 15px;
            margin: 45px 0;
            text-align: center;
        }
    }
}
