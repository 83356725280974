@import "./variables.scss";

.hex-container {
    --w: 800px;
    --n-rows: 3;
    --n-cols: 6;
    --l: calc(var(--w) / var(--n-cols));
    --hl: calc(0.5 * var(--l));
    --ri: calc(0.5 * 1.73205 * var(--l));
    box-sizing: border-box;
    display: grid;
    place-content: center;
    grid-template: repeat(var(--n-rows), var(--l)) / repeat(var(--n-cols), var(--ri));
    grid-gap: var(--hl) 0;
    overflow: hidden;
    margin: 0;
    padding: var(--hl) 0;

    @include respond-to(mobile) {
        --w: 480px;
        --n-rows: 6;
        --n-cols: 4;
    }

    @media only screen and (max-width: 640px) {
        --w: 360px;
    }
}

.hex-cell {
    position: relative;
    overflow: hidden;
    background: $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    filter: drop-shadow(2px 2px 5px);
    grid-column-end: span 2;
    margin: calc(-1 * var(--hl)) 0;
    transform: scale(0.95);
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    cursor: pointer;
    transition: background 200ms;
    text-decoration: none;

    &:hover {
        background: #D8D8D8;
        text-decoration: underline;
        text-decoration-color: #fff;
    }

    &:nth-of-type(5n + 1) {
        grid-column-start: 2;
    }

    @include respond-to(mobile) {
        &:nth-of-type(5n + 1) {
            grid-column-start: unset;
        }
        &:nth-of-type(3n + 1) {
            grid-column-start: 2;
        }
    }

    .title {
        background-color: $blue;
        border-radius: 5px;
        padding: 0.5rem;
        margin: 1rem 0;
        width: 70%;
        height: 2rem;
        display: flex;
        font-size: 16px;
        color: $white;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        justify-content: center;
        align-items: center;

        @include respond-to(mobile) {
            width: 100%;
            margin: 1rem;
            text-align: center;
            position: absolute;
            bottom: 10%;
        }
    }

    .icon {
        width: 97px;
        height: 97px;
        display: block;

        @include respond-to(mobile) {
           position: absolute;
           top: 15%;
        }
    }
}
