@import "./variables.scss";

.adpkd-simulator {
  @include respond-to(mobile) {
    padding: 0 15px;
  }

  .breadcrumbs {
    display: none;

    @include respond-to(mobile) {
      display: flex;
      font-size: 13px;
      margin-top: 15px;
      margin-left: 10px;

      .link-text {
        color: $blue;
        text-decoration: none;
      }

      .page-crumb {
        margin: 0;

        span {
          margin: 0 5px;
        }
      }
    }
  }

  @include respond-to(mobile) {
    .tab-section-content-static {
      width: 100%;

      .patient {
        margin-top: 35px;

        .patient-header {
          .header {
            flex-direction: column;

            img {
              min-width: 128px;
              width: 25%;
              height: 100%;
              margin-right: 15px;
            }

            h1 {
              display: none;
            }

            .patient-summary {
              font-size: 15px;
            }

            .image-container {
              display: flex;
              align-items: center;

              .mobile-patient-dropdown {
                font-size: 24px;
                font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                  "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
                width: 170px;

                .dropdown-shade {
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  position: fixed;
                  display: none;
                  z-index: 1;

                  &.open {
                    display: block;
                  }
                }

                .current-patient {
                  border: 1px solid $border-gray;
                  border-radius: 5px;
                  box-shadow: 0 1px 2px 0 $black;
                  padding: 10px;
                  position: relative;

                  &:after {
                    content: " ";
                    position: absolute;
                    top: 15px;
                    right: 10px;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 8px solid $dark-gray;
                  }

                  &:before {
                    content: " ";
                    position: absolute;
                    bottom: 15px;
                    right: 10px;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 8px solid $dark-gray;
                  }

                  &.open {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                  }
                }

                .dropdown-options {
                  background-color: white;
                  border: 1px solid $border-gray;
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  box-shadow: 0 2px 2px 0 $black;
                  box-sizing: content-box;
                  display: none;
                  flex-direction: column;
                  left: -1px;
                  position: absolute;
                  top: 49px;
                  width: 100%;
                  z-index: 2;

                  a {
                    padding: 10px;
                    color: $black;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                      background-color: $blue;
                    }
                  }

                  &.open {
                    display: flex;
                    border-top: none;
                  }
                }
              }
            }
          }
        }

        .patient-container {
          flex-direction: column;

          .patient-data {
            width: 92%;

            .baseline {
              .cKey {
                width: 67%;
                letter-spacing: -0.3px;
              }

              .alternate-axis {
                .cKey {
                  width: 100%;
                  letter-spacing: 0;
                }
              }
            }
          }

          .patient-timeline-container {
            margin: 0;
            padding-top: 30px;
            border-top: 1px solid $border-gray;

            .patient-timeline {
              .progression {
                text-align: left;
                margin: 0;
                padding: 0 0 60px;
              }

              img.legend {
                top: 30px;
              }

              .viz {
                margin-left: 0;
                width: 100%;

                .zooming-charts-container {
                  width: 100%;
                  height: auto;

                  .chart-container {
                    .chart-header {
                      margin-left: 0;
                      min-height: 30px;
                    }

                    .chart-title {
                      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue",
                        Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
                      font-size: 3.5vw;

                      span.data {
                        font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
                        font-size: 3vw;
                      }

                      span.measurement {
                        font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
                        font-size: 3vw;
                      }
                    }
                  }
                }

                .kidney-image-container {
                  width: 100%;
                  height: auto;

                  .kidney-image {
                    width: 100%;
                    height: auto;
                    position: relative;
                  }
                }
              }

              .patient-slider {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}