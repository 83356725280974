@import "./variables.scss";

.other-risk-factors {
    color: $dark-gray;
    font-size: 16px;
    width: 938px;
    margin: 0 auto;
    @include respond-to(mobile) {
        width: initial;
        padding: 0 10px;
    }
    h1 {
        font-size: 36px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        @include respond-to(mobile) {
            font-size: 30px;
        }
    }
    .description {
        font-size: 16px;
        @include respond-to(mobile) {
            font-size: 15px;
            sup {
                font-size: 10px;
            }
        }
    }
    h3 {
        font-size: 24px;
        span {
            font-size: 19px;
            font-weight: normal;
        }
    }
    h4 {
        font-size: 20px;
    }
    img {
        width: 100%;
        padding: 2rem;
        box-sizing: border-box;
    }
    .flex-container {
        display: flex;
        flex-direction: row;
        // align-items: center;
        justify-content: center;
        & > * {
            flex: 1;
        }
        @include respond-to(mobile) {
            flex-direction: column;
        }
    }
    .box {
        padding: 0rem 2rem;
        padding-bottom: 1rem;
        border: 1px solid $gray;
        border-radius: 4px;
        margin: 0 1rem;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

    }

    .footer-section {
        margin-top: 60px;
        .cross-link-container {
            margin-top: 0;
        }
        .title {
            font-size: 16px;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            margin: 20px 0 0;
        }
        .references {
            font-size: 11px;
            margin-top: 30px;
            .title {
                display: block;
                font-size: 14px;
            }
            p {
                margin: 5px 0;
            }
        }
        .text-link {
            cursor: pointer;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        }
        div.disclaimer {
            font-size: 15px;
            margin: 45px 0;
            text-align: center;
        }
    }
}
