@import "../../../css/variables.scss";

.information-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 20px;

    @include respond-to(mobile) {
        flex-direction: column;
    }

    a[class="information-card"] {
        &:hover {
            filter: invert(15%);
            cursor: pointer;
        }

        color: inherit; /* blue colors for links too */
        text-decoration: inherit; /* no underline */
        background-color: $light-blue;

        flex: 1;

        display: flex;
        flex-direction: column;

        text-align: center;

        p {
            margin: 0;
            padding: 10px;
        }

        .icon-area {
            padding: 10px;
            svg {
                stroke: white;
                fill: white;
            }
        }
        .main-title-area {
            padding: 10px;
            background-color: #3a4651;
            color: white;
            height: 4rem;
        }
        .sub-title-area {
            flex: 1;

            padding: 10px;
            color: $dark-blue;
            background-color: transparent;
            font-weight: bold;
        }

        img[class="icon"] {
            aspect-ratio: 1;
        }
    }
}
