.tabbed-top {
    display: flex;
    height: 68px;
    justify-content: space-between;
    margin-top: 40px;
}

.tabbed-top .app-title {
    font-size: 23px;
    font-family: "Apex Sans Light";
    font-weight: 500;
    margin: 23px 0 16px 30px;
    min-width: 220px;
}

.tabbed-top .tab-nav {
    display: flex;
    align-items: center;
    margin: auto;
    font-size: 15px;
    flex-direction: row;
    line-height: 1.1rem;
}

.tabbed-top .tab-nav .tab {
    border-left: 1px solid #c9c9c9;
    cursor: pointer;
    min-width: 80px;
    padding: 0 24px 0 24px;
    height: 48px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6a6a6a;
    text-decoration: none;
    text-align: center;
    font-size: smaller;
}
.tabbed-top .tab-nav .tab:last-of-type {
    border-right: 1px solid #c9c9c9;
}

.tabbed-top .tab-nav .tab.selected {
    background-color: white;
    color: #4a4a4a;
    font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
        "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
    font-weight: 800;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .tabbed-top .tab-nav {
        line-height: 1.5rem;
    }
}

.tabbed-section-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
