@import "./variables.scss";

.tkv-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  .modal-content {
    position: fixed;
    background: $light-gray;
    overflow-y: scroll;
    width: 950px;
    max-height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 40px 30px 50px;
    @include respond-to(mobile) {
      width: 90%;
      height: 70%;
      font-size: 15px;
      padding: 60px 10px;
    }
    .view-switch {
      background-color: $white;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 15px 0;
      width: 506px;
      position: absolute;
      right: 40px;
      @include respond-to(mobile) {
        width: 300px;
        position: relative;
        right: 0;
        padding: 15px;
        justify-content: flex-start;
      }
      .radio-row {
        border: none;
        display: block;
        padding: 0;
        .radio-container {
          cursor: pointer;
          display: inline-block;
          margin: 0 20px;
          position: relative;
          padding-left: 20px;
          @include respond-to(mobile) {
            margin: 0 10px;
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: $white;
            border: 1px solid $border-gray;
            border-radius: 50%;
          }
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          input:checked ~ .checkmark:after {
            display: block;
          }
          .checkmark:after {
            top: 4px;
            left: 4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $blue;
          }
        }
      }
    }
    .typical,
    .atypical {
      display: flex;
      margin-top: 80px;
      @include respond-to(mobile) {
        flex-direction: column;
        position: relative;
      }
    }
    @include respond-to(mobile) {
      .atypical {
        .img-container {
          margin-bottom: 175px;
        }
      }
    }
    .content-container {
      position: relative;
      @include respond-to(mobile) {
        position: initial;
      }
      .title {
        text-align: left;
        font-size: 36px;
        font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
        margin: 0;
      }
      .disclaimer {
        font-size: 17px;
        font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
      }
      .description {
        font-size: 14px;
      }
      .footer-section {
        bottom: 75px;
        position: absolute;
        @include respond-to(mobile) {
          bottom: 0;
        }
        .glossary {
          margin-top: 45px;
        }
        .glossary,
        .references {
          font-size: 11px;
        }
        @include respond-to(mobile) {
          .references {
            margin-bottom: 0;
          }
        }
      }
    }
    .img-container {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      img {
        border: 4px solid $dark-blue;
      }
      .img-row {
        display: flex;
        @include respond-to(mobile) {
          flex-direction: column;
        }
        span:first-of-type {
          margin-right: 10px;
          @include respond-to(mobile) {
            margin: 0;
          }
        }
        p {
          font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
          margin-top: 0;
          width: 240px;
        }
        img {
          width: 240px;
          height: 170px;
        }
      }
      .footnote {
        font-size: 14px;
        width: 500px;
        @include respond-to(mobile) {
          margin-bottom: 30px;
          width: 240px;
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 33px;
    height: 33px;
    opacity: 0.3;
    cursor: pointer;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before,
  .close:after {
    position: absolute;
    content: " ";
    left: 15px;
    height: 34px;
    width: 2px;
    background-color: $border-gray;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}
