.modal-screen {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10002;
}

.modal-container .modal-center {
  display: block;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  width: 90%;
  height: initial;
  z-index: 10002;
  max-height: 700px;
  max-width: 1080px;
}
