@import "./variables.scss";

.genetic-testing {
    color: $dark-gray;
    font-size: 16px;
    width: 938px;
    margin: 0 auto;
    @include respond-to(mobile) {
        width: initial;
        padding: 0 10px;
    }
    h1 {
        font-size: 36px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        @include respond-to(mobile) {
            font-size: 30px;
        }
    }
    .description {
        font-size: 16px;
        @include respond-to(mobile) {
            font-size: 15px;
            sup {
                font-size: 10px;
            }
        }
    }
    h3 {
        text-align: center;
        font-size: 24px;
        span {
            font-size: 19px;
            font-weight: normal;
        }
    }
    .icon {
        width: 96px;
        height: 96px;
        justify-self: center;
    }
    section {
        display: flex;
        flex-direction: row;
        border: 1px solid $medium-gray;
        border-radius: 4px;
        background-color: $light-gray;
        margin-bottom: 2rem;

        @include respond-to(mobile) {
            flex-direction: column;
        }

        .title {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            padding: 1rem;
            background-color: $medium-gray;
            color: white;
            @include respond-to(mobile) {
                padding: 0;
                padding-top: 1rem;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1rem 2rem;
            flex: 3;
        }
        
        summary {
            cursor: pointer;
            &::marker {
                color: $blue;
            }
        }
    }
    ul.list {
        &.image {
            list-style: none;
            & > li {
                padding: 6px 0 6px 32px;
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 24px;
            }
            &.dna {
                & > li {
                    background-image: url(../images/list-icon-dna.png);
                }
            }
        }
    }

    .footnote {
        font-size: 12px;
        &.mri {
            margin-top: 20px;
        }
    }

    .footer-section {
        margin-top: 60px;
        .cross-link-container {
            margin-top: 0;
        }
        .title {
            font-size: 16px;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            margin: 20px 0 0;
        }
        .references {
            font-size: 11px;
            margin-top: 30px;
            .title {
                display: block;
                font-size: 14px;
            }
            p {
                margin: 5px 0;
            }
        }
        .text-link {
            cursor: pointer;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        }
        div.disclaimer {
            font-size: 15px;
            margin: 45px 0;
            text-align: center;
        }
    }
}
