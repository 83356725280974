@use "sass:color";
@import "../css/variables.scss";

.table-wrapper-c696c390-4d32-4bb7-be44-99e6bb6b45ec {
    width: 100%;

    table {
        table-layout: fixed;
        width: 100%;

        th,
        td {
            padding: 10px;
            text-align: left;
        }

        thead {
            tr {
                th[scope="col"] {
                }
                th[scope="row"] {
                }

                td {
                }
            }
        }
        tbody {
            tr {
                th[scope="col"] {
                    background-color: $blue;
                }
                th[scope="row"] {
                    // width: clamp(200px, 18vw, 300px);
                    background-color: darken($color: $light-blue, $amount: 8);
                    color: $white;

                    img {
                        width: 100%;
                    }
                }

                td {
                    background-color: $light-blue;
                }
            }
        }
    }
}
