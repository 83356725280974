@import "./variables.scss";

html {
  background-color: $dark-blue;
}

html,
body,
#root {
  height: 100%;
}

#adpkd-app {
  min-height: 100%;
}

#adpkd-app > div:nth-child(2) {
  flex-grow: 1;
}

#adpkd-app {
  background-color: white;
  color: $dark-gray;
  display: flex;
  flex-direction: column;
  .centered {
    text-align: center;
  }
  .no-wrap {
    display: inline-block;
    word-break: keep-all;
    display: flex;
    align-items: center;
  }
  .text-link {
    color: $blue;
    cursor: pointer;
    text-decoration: none;
  }
  .site-header {
    color: $white;
    height: 80px;
    background-color: $dark-blue;
    border-bottom: 9px solid $blue;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @include respond-to(mobile) {
      min-height: 60px;
      justify-content: space-between;
      align-items: center;
      border: none;
      padding: 0 25px;
    }
    &.active {
      border-bottom: 9px solid $border-gray;
      @include respond-to(mobile) {
        border: none;
      }
    }
    .home-icon {
      display: flex;
      height: 65px;
      align-items: center;
      margin-right: 10px;
    }
    .nav-container {
      display: flex;
      @include respond-to(mobile) {
        flex-direction: column;
        position: absolute;
        top: -200px;
        left: 0;
        opacity: 0;
        width: 100%;
        transition: all cubic-bezier(0.73, 0.01, 0.2, 1) 1s;
        z-index: 1;
        &.active {
          top: 80px;
          opacity: 1;
        }
      }
      .header-nav {
        @include respond-to(mobile) {
          align-items: flex-start;
          flex-direction: column;
          border: none;
        }
        .link-reset {
          color: inherit;
          text-decoration: none;
          background-color: $header-gray;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          font-size: 20px;
          margin: 0 5px;
          padding: 20px 0;
          width: 210px;
          display: inline-block;
          text-align: center;
          @include respond-to(mobile) {
            display: block;
            width: 100%;
            text-align: left;
            margin: 0;
            border-radius: 0;
            padding: 10px;
            border-top: 1px solid $tabbed-gray;
            border-left: 10px solid $header-gray;
            box-sizing: border-box;
          }
          &.active {
            background-color: $blue;
            @include respond-to(mobile) {
              background-color: $header-gray;
              border-left: 10px solid $blue;
            }
          }
        }
      }
      .sign-up {
        color: $white;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
          "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        display: flex;
        height: 65px;
        align-items: center;
        margin-left: 10px;
        @include respond-to(mobile) {
          background-color: $header-gray;
          font-size: 13px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          margin: 0;
          border-radius: 0;
          padding: 10px;
          border-top: 1px solid $tabbed-gray;
          border-left: 10px solid $header-gray;
          height: 25px;
        }
      }
    }
    .hamburger {
      display: none;
      @include respond-to(mobile) {
        width: 35px;
        height: 30px;
        margin: 10px 10px;
        position: relative;
        cursor: pointer;
        display: inline-block;
        span {
          background-color: #fff;
          position: absolute;
          border-radius: 2px;
          transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
          width: 100%;
          height: 4px;
          transition-duration: 500ms;
        }
        span:nth-child(1) {
          top: 0px;
          left: 0px;
        }
        span:nth-child(2) {
          top: 13px;
          left: 0px;
        }
        span:nth-child(3) {
          bottom: 0px;
          left: 0px;
        }
        &.active span:nth-child(1) {
          transform: rotate(45deg);
          top: 13px;
        }
        &.active span:nth-child(2) {
          transform: scale(0.1);
        }
        &.active span:nth-child(3) {
          transform: rotate(-45deg);
          top: 13px;
        }
      }
    }
    .signup-modal {
      position: absolute;
      .modal-shade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 100000;
      }
      .content {
        position: fixed;
        overflow-y: scroll;
        background: $light-gray;
        width: 50%;
        max-width: 630px;
        height: 80%;
        min-height: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 50px 40px 60px;
        display: flex;
        font-size: 16px;
        color: $dark-gray;
        text-align: center;
        z-index: 100001;
        @include respond-to(mobile) {
          width: 90%;
          height: 70%;
          min-height: initial;
          font-size: 15px;
          padding: 50px 10px;
        }
        &.success {
          height: initial;
          min-height: initial;
        }
        .submitted {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          h1 {
            font-size: 36px;
            margin: 0 0 10px;
          }
          p {
            margin: 0;
          }
          .button {
            border: none;
            border-radius: 5px;
            color: $white;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 130px;
            font-size: 14px;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
              "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            margin-top: 40px;
            &.submit {
              background-color: $blue;
            }
          }
        }
        .form-container {
          p {
            margin: 10px 0;
          }
          .error-container {
            margin: 10px 0 0;
            .error {
              font-size: 12px;
              color: $error;
              margin: 0 0 10px;
            }
          }
          .title {
            font-size: 36px;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
              "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            margin: 0;
          }
          form {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 60px;
            input[type="checkbox"]:focus ~ .checkmark,
            input[type="radio"]:focus ~ .checkmark {
              outline: -webkit-focus-ring-color auto 5px;
            }
            .other-fields-container {
              margin-top: 20px;

              @include respond-to(mobile) {
                width: 100%;
              }
            }
            .field {
              width: 480px;
              margin: 0 auto 5px;
              display: flex;
              align-items: center;
              position: relative;
              justify-content: center;
              @include respond-to(mobile) {
                width: 100%;
              }
              &:last-of-type {
                margin-bottom: 25px;
              }
              &.hasError {
                span:after {
                  content: "X";
                  color: $error;
                  position: absolute;
                  right: 10px;
                  top: 10px;
                }
                .error-text {
                  display: block;
                }

                label {
                  margin-bottom: 15px;
                }
              }
              label {
                display: inline-block;
                font-size: 15px;
                margin-right: 10px;
                text-align: right;
                width: 130px;
                @include respond-to(mobile) {
                  font-size: 14px;
                  width: 90px;
                }
              }
              input {
                border: 1px solid $gray;
                border-radius: 5px;
                cursor: pointer;
                height: 32px;
                width: 254px;
                padding: 2px 8px;
                font-size: 16px;
                @include respond-to(mobile) {
                  width: 169px;
                }
              }
              .select-container {
                @include respond-to(mobile) {
                  width: 184px;
                }
                .react-select__option--is-focused,
                .react-select__option:hover {
                  background-color: $blue;
                }
                .react-select__control--is-focused {
                  outline: -webkit-focus-ring-color auto 5px;
                }
                @include respond-to(mobile) {
                  .react-select__control {
                    width: 100%;
                  }
                }
              }
              .error-text {
                display: none;
                color: $error;
                font-size: 12px;
                line-height: 15px;
                text-align: left;
              }
            }
            .button-container {
              display: flex;
              align-content: center;
              justify-content: center;
              margin-top: 45px;
              .button {
                border: none;
                border-radius: 5px;
                color: $white;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 130px;
                font-size: 14px;
                font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                  "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
                &.cancel {
                  background-color: $border-gray;
                }
                &.submit {
                  background-color: $blue;
                }
              }
              .button:first-of-type {
                margin-right: 20px;
              }
            }
          }
          .opt-in-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid $gray;
            border-radius: 5px;
            margin-top: 40px;
            padding: 25px 0;
            width: 100%;
            p {
              font-size: 15px;
              margin: 0 0 10px;
              &.right-aligned {
                text-align: right;
                width: 382px;
                @include respond-to(mobile) {
                  width: 287px;
                }
              }
            }
            .radio-row {
              border: none;
              display: block;
              width: 300px;
              margin: 0 auto;
              padding: 0;
              @include respond-to(mobile) {
                width: initial;
              }
              .radio-container {
                cursor: pointer;
                display: inline-block;
                margin: 0 20px;
                position: relative;
                padding-left: 20px;
                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                }
                .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 16px;
                  width: 16px;
                  background-color: $white;
                  border: 1px solid $border-gray;
                  border-radius: 50%;
                }
                .checkmark:after {
                  content: "";
                  position: absolute;
                  display: none;
                }
                input:checked ~ .checkmark:after {
                  display: block;
                }
                .checkmark:after {
                  top: 4px;
                  left: 4px;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: $blue;
                }
              }
            }
            .opt-in-container {
              margin: 30px 0;
              @include respond-to(mobile) {
                width: initial;
              }
              .opt-in-field {
                width: 450px;
                cursor: pointer;
                display: block;
                text-align: left;
                position: relative;
                padding-left: 25px;
                margin: 0 auto;
                @include respond-to(mobile) {
                  width: initial;
                  margin: 0 15px;
                }
                p {
                  font-size: 12px;
                }
                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 0;
                  width: 0;
                }
                .checkmark {
                  position: absolute;
                  top: 5px;
                  left: 0;
                  height: 16px;
                  width: 16px;
                  background-color: $white;
                  border: 1px solid $border-gray;
                  border-radius: 5px;
                }
                .checkmark:after {
                  content: "";
                  position: absolute;
                  display: none;
                }
                input:checked ~ .checkmark {
                  background-color: #2196f3;
                  &:after {
                    display: block;
                  }
                }
                .checkmark:after {
                  left: 5px;
                  top: 0px;
                  width: 4px;
                  height: 10px;
                  border: solid $white;
                  border-width: 0 2px 2px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
              }
            }
          }
        }
      }
      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 33px;
        height: 33px;
        opacity: 0.3;
        cursor: pointer;
      }
      .close:hover {
        opacity: 1;
      }
      .close:before,
      .close:after {
        position: absolute;
        content: " ";
        left: 15px;
        height: 34px;
        width: 2px;
        background-color: $border-gray;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
    }
  }
  .footer {
    background-color: $dark-blue;
    color: $white;
    min-height: 80px;
    font-size: 13px;
    padding: 32px 24px 0 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    @include respond-to(mobile) {
      font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
      font-size: 12px;
      flex-direction: column;
      padding: 25px 15px 10px;
      position: relative;
      align-items: flex-start;
    }
    .navigation-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;
      .modal-content {
        position: fixed;
        background: $light-gray;
        overflow-y: scroll;
        width: 50%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 60px 40px 30px 50px;
        z-index: 2;
        @include respond-to(mobile) {
          width: 90%;
          // height: 70%;
          font-size: 15px;
          padding: 60px 10px;
        }
        .title {
          font-size: 18px;
          margin-top: 0;
          color: $dark-gray;
          font-weight: bold;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        }
        .modal-message {
          margin-bottom: 40px;
        }
        .button-container {
          display: flex;
          align-content: center;
          justify-content: center;
          margin-top: 45px;
          .button {
            border: none;
            border-radius: 5px;
            color: $white;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 130px;
            font-size: 14px;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
              "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            &.cancel {
              background-color: $border-gray;
            }
            &.confirm {
              background-color: $blue;
              text-decoration: none;
            }
          }
          button {
            margin-right: 20px;
          }
        }
      }
      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 33px;
        height: 33px;
        opacity: 0.3;
        cursor: pointer;
      }
      .close:hover {
        opacity: 1;
      }
      .close:before,
      .close:after {
        position: absolute;
        content: " ";
        left: 15px;
        height: 34px;
        width: 2px;
        background-color: $border-gray;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
    }
    .flex {
      display: flex;
      @include respond-to(mobile) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
      }
      &.legal {
        justify-content: space-between;
        align-items: center;
        bottom: 0;
        padding: 1rem 0;
        @include respond-to(mobile) {
          align-items: flex-start;
          position: relative;
          width: 100%;
          .flex {
            margin: 0;
          }
        }
      }
      &.privacy-terms {
        justify-content: flex-end;
        a {
          margin: 6px;
          color: $white;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.sitemap {
        justify-content: center;
        padding: 30px 0 50px;
        flex-grow: 1;
      }
      img {
        width: 50px;
        margin-right: 30px;
      }
      .links-container {
        border-left: 1px solid $border-gray;
        flex-direction: column;
        padding: 0 1.5vw 20px;
        width: 17%;
        @include respond-to(mobile) {
          border-top: 1px solid $border-gray;
          border-left: none;
          padding: 20px 0 10px;
          width: 200px;
          min-width: 200px;
          &:last-of-type {
            border-bottom: 1px solid $border-gray;
          }
          &:nth-child(2) {
            .link-reset {
              &.header {
                margin-bottom: 10px;
              }
            }
          }
        }
        .link-reset {
          color: $white;
          display: block;
          font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 5px;
          text-decoration: none;
          @include respond-to(mobile) {
            margin-bottom: 10px;
          }
          &:hover {
            text-decoration: underline;
          }
          &.header {
            color: $blue;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
              "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .date {
      text-align: center;
      @include respond-to(mobile) {
        align-self: flex-start;
        position: absolute;
        bottom: 0;
      }
    }
    .number {
      text-align: right;
      @include respond-to(mobile) {
        display: inline-flex;
        align-self: flex-end;
      }
    }
  }
  @include respond-to(desktop) {
    .mobile-only {
      display: none;
    }
  }
  @include respond-to(mobile) {
    .desktop-only {
      display: none;
    }
  }
  .symbol {
    font-family: "Helvetica";
  }
  .copy-symbol {
    font-size: 20px;
    line-height: 1px;
    display: inline-block;
  }

  .button-reset {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    padding: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
  }
  .navigation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
    .modal-content {
      position: fixed;
      background: $light-gray;
      overflow-y: scroll;
      width: 50%;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 60px 40px 30px 50px;
      z-index: 2;
      @include respond-to(mobile) {
        width: 90%;
        // height: 70%;
        font-size: 15px;
        padding: 60px 10px;
      }
      .title {
        font-size: 18px;
        margin-top: 0;
      }
      .modal-message {
        margin-bottom: 40px;
      }
      .button-container {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-top: 45px;
        .button {
          border: none;
          border-radius: 5px;
          color: $white;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 130px;
          font-size: 14px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          &.cancel {
            background-color: $border-gray;
          }
          &.confirm {
            background-color: $blue;
            text-decoration: none;
          }
        }
        button {
          margin-right: 20px;
        }
      }
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 33px;
      height: 33px;
      opacity: 0.3;
      cursor: pointer;
    }
    .close:hover {
      opacity: 1;
    }
    .close:before,
    .close:after {
      position: absolute;
      content: " ";
      left: 15px;
      height: 34px;
      width: 2px;
      background-color: $border-gray;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }
  }
}

img[class="kidney-row-item"] {
  // width: 100%;
  background-color: white;
  width: 100%;
  object-fit: cover;
}
