.slider-labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 0px;
  margin: 0px;
  color: #58595b;
  font-size: 10.5px;
  pointer-events: none;
  /* border: 1px solid yellow; */
}
