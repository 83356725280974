@import "../../../../css/variables.scss";

.charts-modal-container {
  position: relative;
  display: flex;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.3);
  background-color: $white;
  padding: 53px 40px;
  flex-direction: row;

  @include respond-to(mobile) {
    overflow: scroll;
    padding: 60px 10px 40px;
    flex-direction: column;
    max-height: 450px;

    .chart-container {
      width: 100%;

      &:nth-child(1) {
        margin-right: 0;
        margin-bottom: 40px;
      }

      &:nth-child(2) {
        margin-right: 0;
      }

      .chart-header {
        text-align: center;
      }

      .VictoryContainer {
        height: initial !important;

      }
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 33px;
    height: 33px;
    opacity: 0.3;
    cursor: pointer;
  }

  .close:hover {
    opacity: 1;
  }

  .close:before,
  .close:after {
    position: absolute;
    content: " ";
    left: 15px;
    height: 34px;
    width: 2px;
    background-color: $border-gray;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }
}