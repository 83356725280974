@import "./variables.scss";

.tkv-calculator-app {
  color: $dark-gray;
  font-size: 14px;
  margin: 0 auto;
  width: 938px;
  @include respond-to(mobile) {
    width: initial;
    padding: 0 10px;
  }
  h1 {
    font-size: 36px;
    font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
      "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
      "Arial-Bold", Arial, sans-serif;
    @include respond-to(mobile) {
      font-size: 30px;
    }
  }
  .description {
    font-size: 16px;
    @include respond-to(mobile) {
      font-size: 15px;
      sup {
        font-size: 10px;
      }
    }
  }
  h3 {
    font-size: 24px;
    span {
      font-size: 19px;
      font-weight: normal;
    }
  }
  .typical-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .typical-button {
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
      "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
      "Arial-Bold", Arial, sans-serif;
      margin: 0rem 0.5rem;
      border: none;
      background: $blue;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      transition: background 200ms;
      &:hover {
        background: $dark-blue;
      }
    }
  }
  .footnote {
    font-size: 12px;
    &.mri {
      margin-top: 20px;
    }
  }
  .patient-selector {
    background: $light-gray;
    border-radius: 5px 5px 0 0;
    margin: 20px 0 50px;
    padding: 40px 0 0;
    text-align: center;
    @include respond-to(mobile) {
      border-radius: 0;
      margin: 20px -10px 50px;
    }
    .title {
      color: $dark-blue;
      font-size: 20px;
      margin: 0 0 30px;
      @include respond-to(mobile) {
        font-size: 16px;
      }
    }
    .patient {
      background-color: transparent;
      border: 2px solid $white;
      border-bottom: none;
      border-radius: 5px 5px 0 0;
      color: $blue;
      cursor: pointer;
      display: flex;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
        "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
        "Arial-Bold", Arial, sans-serif;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      width: 218px;
      height: 175px;
      margin: 0 10px 0;
      text-decoration: none;
      &:last-of-type {
        span {
          height: 30px;
        }
      }
      @include respond-to(mobile) {
        margin: 0 5px 0;
        padding: 10px 0 8px;
        width: 30%;
        height: auto;
        &:last-of-type {
          span {
            padding: 0 10px;
            font-size: 11px;
          }
        }
      }
      .selector-title {
        display: flex;
        flex-direction: column;
        .name {
          font-size: 17px;
          @include respond-to(mobile) {
            font-family: "ApexSans-Bold", "Helvetica Neue Bold",
              "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
              "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            font-size: 13px;
          }
        }
        .height {
          font-size: 13px;
          @include respond-to(mobile) {
            font-family: "ApexSans-Bold", "Helvetica Neue Bold",
              "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
              "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            font-size: 11px;
          }
        }
      }
      img {
        margin: 10px 0 0;
        width: 100px;
        height: auto;
        @include respond-to(mobile) {
          min-width: 50px;
          width: 45%;
        }
      }
      svg {
        margin: 10px 0 0;
        @include respond-to(mobile) {
          min-width: 50px;
          width: 45%;
          height: auto;
        }
        &#tkv-patient {
          .cls-1 {
            fill: none;
          }
          .cls-2 {
            fill: transparent;
          }
          .cls-3 {
            opacity: 0.15;
            fill: url(#radial-gradient);
          }
          .cls-4 {
            clip-path: url(#clip-path);
          }
          .cls-5 {
            fill: #868686;
          }
        }
      }
      &.selected {
        background-color: $white;
      }
    }
    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .skip-to-step-3-wrap {
    margin-bottom: 30px;

    .skip-to-step-3 {
      display: block;
      text-align: left;
      position: relative;
      padding: 15px 15px 15px 40px;
      margin: 0 auto;
      border: 1px solid $border-gray-alt;
      border-radius: 5px;

      .input-wrap {
        position: absolute;
        left: 15px;
        top: 15px;
      }

      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
        height: 16px;
        width: 16px;
        margin: 0;
      }

      label,
      input {
        cursor: pointer;
      }

      .checkmark {
        height: 16px;
        width: 16px;
        background-color: $white;
        border: 1px solid $border-gray;
        display: block;
        pointer-events: none;

        &::after {
          content: "";
          position: absolute;
          display: none;
          left: 6px;
          top: 1px;
          width: 5px;
          height: 11px;
          border: solid $blue;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      input:checked + .checkmark {
        &:after {
          display: block;
        }
      }
    }

    .steps-hidden-message p {
      font-size: 11px;
    }
  }

  .kidney-mri-container {
    display: flex;
    height: 370px;
    @include respond-to(mobile) {
      flex-direction: column;
      height: 100%;
    }
    p {
      font-size: 16px;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
        "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
        "Arial-Bold", Arial, sans-serif;
    }
    img {
      width: 100%;
    }
    .sagittal-coronal-container {
      display: flex;
      margin-right: 40px;
      @include respond-to(mobile) {
        margin-right: 0;
        div {
          width: 50%;
        }
      }
      div:first-of-type {
        margin-right: 10px;
      }
    }
  }
  .footer-section {
    margin-top: 60px;
    .cross-link-container {
      margin-top: 0;
    }
    .title {
      font-size: 16px;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
        "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
        "Arial-Bold", Arial, sans-serif;
      margin: 20px 0 0;
    }
    .references {
      font-size: 11px;
      margin-top: 30px;
      .title {
        display: block;
        font-size: 14px;
      }
      p {
        margin: 5px 0;
      }
    }
    .text-link {
      cursor: pointer;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
        "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
        "Arial-Bold", Arial, sans-serif;
    }
    div.disclaimer{
      font-size: 15px;
      margin: 45px 0;
      text-align: center;
    }
  }
  section {
    border: 1px solid $gray;
    border-radius: 5px;
    margin: 30px 0;
    .padded-section {
      padding: 0 20px;
      overflow: hidden;
      height: 0;
      visibility: hidden;

      &.open {
        height: auto;
        visibility: visible;
      }

      @include respond-to(mobile) {
        padding: 0 10px;
      }

      .content-wrap {
        padding-top: 40px;
        padding-bottom: 40px;

        > .calculate-tkv-form {
          margin-top: 0;
        }

        > span .calculate-tkv-form:last-child {
          margin-bottom: 40px;
        }
      }
    }
    #kidney-dimensions-form {
      width: 680px;
      margin: 0 auto 40px;
      @include respond-to(mobile) {
        width: 100%;
      }
      .prompt {
        font-size: 16px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        text-align: center;
        margin-bottom: 30px;
      }
      .form-container {
        .error-container {
          text-align: center;
          width: 70%;
          margin: 0 auto 20px;
          font-size: 13px;
          .error {
            color: $error;
            margin: 0;
            &.description {
              font-size: 13px;
              margin-bottom: 10px;
            }
          }
        }
      }
      .flex {
        display: flex;
        align-items: center;
        justify-content: center;
        &.row {
          justify-content: space-around;
        }
      }
      label {
        text-align: center;
      }
      label,
      input {
        display: block;
        margin-bottom: 10px;
        width: 100px;
        @include respond-to(mobile) {
          width: 80px;
        }
      }
      .input-container {
        position: relative;
        &.left.hasError:before {
          content: "X";
          color: $error;
          position: absolute;
          left: -12px;
          top: 15px;
        }
        &.right.hasError:after {
          content: "X";
          color: $error;
          position: absolute;
          right: -12px;
          top: 15px;
        }
        input,
        .animated-input {
          color: $blue;
          font-size: 18px;
          height: 40px;
          text-align: center;
          border: 1px inset $border-gray;
          align-items: center;
          justify-content: center;
          display: flex;
          margin-bottom: 5px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold",
            "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
            "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          span.vis-hidden {
            opacity: 0;
            display: block;
          }
          span.animated {
            display: block;
          }
        }
      }
      .label-container {
        @include respond-to(mobile) {
          margin: 0 10px;
        }
        label {
          height: 45px;
          align-items: flex-end;
          display: flex;
          justify-content: center;
          sup {
            padding-bottom: 5px;
          }
        }
      }
      img {
        height: auto;
        width: 214px;
        @include respond-to(mobile) {
          display: none;
        }
      }
      .calculate-tkv-button {
        align-items: center;
        background-color: $dark-blue;
        border-radius: 5px;
        color: $white;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        height: 37px;
        justify-content: center;
        margin: 10px auto 0;
        width: 228px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
      }
    }
    .calculate-tkv-form {
      margin-top: 40px;
      box-sizing: border-box;
      .vis-hidden {
        opacity: 0;
        display: inline-block;
      }
      div.animated {
        display: inline-block;
      }
      .flex {
        display: flex;
        align-items: center;
        position: relative;
        @include respond-to(mobile) {
          flex-direction: column;
        }
        .conversionText {
          position: absolute;
          left: 145px;
          bottom: -35px;
          @include respond-to(mobile) {
            position: relative;
            left: 0;
          }
        }
      }
      .steps {
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        margin-right: 12px;
        text-align: right;
        width: 130px;
        font-size: 16px;
        @include respond-to(mobile) {
          margin-right: 0;
          margin-bottom: 25px;
          text-align: center;
          width: 100%;
          br {
            display: none;
          }
        }
      }
      .formula-container {
        align-items: center;
        justify-content: center;
        background-color: $light-gray;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        height: 80px;
        padding: 0;
        width: 298px;
        &.highlight {
          border: 1px solid $blue;
        }
        @include respond-to(mobile) {
          margin-bottom: 15px;
          width: 91%;
          &:last-of-type:before {
            content: "+";
            font-size: 20px;
            position: absolute;
            left: 0px;
          }
          .flex {
            flex-direction: row;
          }
        }
        .pi-formula {
          align-items: center;
          display: inline-flex;
          flex-direction: column;
          font-size: 18px;
          margin-right: 7px;
          padding: 16px 0;
          @include respond-to(mobile) {
            margin-right: 4px;
          }
          .pi-symbol {
            border-bottom: 2px solid $border-gray;
            padding: 2px 8px;
            @include respond-to(mobile) {
              padding: 2px 4px;
            }
          }
        }
        .times-symbol {
          font-size: 20px;
          vertical-align: top;
          @include respond-to(mobile) {
            font-size: 15px;
          }
        }
        .dimension-formula-container {
          align-items: center;
          display: flex;
          flex-direction: column;
          margin: -16px 0 0 5px;
          @include respond-to(mobile) {
            margin: -25px 0 0 3px;
          }
          .kidney-title {
            margin-bottom: 5px;
          }
          .dimension-formula {
            .vis-hidden {
              opacity: 0;
              display: inline-block;
            }
            div.animated {
              display: inline-block;
            }
            font-size: 17px;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold",
              "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
              "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
            .kidney-value {
              color: $blue;
              @include respond-to(mobile) {
                span {
                  font-size: 15px;
                }
              }
            }
            sub {
              font-size: 10px;
            }
            .times-symbol {
              margin: 0 2px;
            }
          }
        }
      }
      .plus-symbol {
        font-size: 18px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        margin: 0 12px;
        @include respond-to(mobile) {
          display: none;
        }
      }
      .formula-result {
        align-items: center;
        display: flex;
        font-size: 18px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        margin-left: 12px;
        max-width: 112px;
        @include respond-to(mobile) {
          border-top: 1px solid $border-gray;
          flex-direction: column;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold",
            "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
            "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          font-size: 20px;
          margin: 0;
          padding-top: 15px;
          width: 100%;
          max-width: initial;
          &.final {
            flex-direction: row;
            justify-content: center;
          }
        }
        .vis-hidden {
          opacity: 0;
          display: inline-block;
        }
      }
      .tkv-value-container {
        border: 1px solid $blue;
        border-radius: 5px;
        display: inline-block;
        font-size: 14px;
        height: 70px;
        padding: 10px 5px 0;
        text-align: center;
        width: 100%;
        .tkv-value {
          color: $blue;
          font-size: 20px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold",
            "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
            "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          span {
            font-size: 16px;
          }
        }
      }
    }

    .calculate-httkv-form-2 {
      margin-top: 40px;
    }

    .calculate-httkv-form {
      .htTKV-calculation-container {
        position: relative;
        text-align: center;
        .error-container {
          font-size: 13px;
          .error {
            color: $error;
            margin: 0 0 10px;
          }
        }
        .formula-container {
          @include respond-to(mobile) {
            width: 110px;
            padding: 10px;
          }
        }
      }
      .convert-open-link {
        position: absolute;
        left: 20px;
        bottom: -25px;
        @include respond-to(mobile) {
          left: -10px;
          width: 150px;
        }
      }
      .convert-confirm-link,
      .convert-open-link {
        color: $blue;
        cursor: pointer;
        text-decoration: none;
      }
      .flex {
        display: flex;
        align-items: center;
        margin-left: 200px;
        @include respond-to(mobile) {
          margin: 0;
          padding-top: 43px;
          position: relative;
          width: 100%;
        }
      }
      .steps {
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        margin-right: 12px;
        text-align: right;
        width: 100px;
        @include respond-to(mobile) {
          display: block;
          font-size: 16px;
          margin-right: 0;
          margin-bottom: 25px;
          position: absolute;
          text-align: center;
          top: 0;
          width: 100%;
          br {
            display: none;
          }
        }
      }
      .formula-container {
        align-items: center;
        background-color: $light-gray;
        border-radius: 5px;
        display: flex;
        font-size: 18px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        flex-direction: column;
        height: 80px;
        justify-content: center;
        padding: 10px 20px;
        width: 140px;

        &.two-inputs {
          height: 90px;
        }

        @include respond-to(mobile) {
          width: 110px;
          padding: 10px;
        }
        .vis-hidden {
          opacity: 0;
          display: inline-block;
        }
        div.animated {
          display: inline-block;
          width: 100%;
        }
        .tkv-value {
          border-bottom: 2px solid $border-gray;
          color: $blue;
          display: block;
          margin-bottom: 5px;
          padding: 0 0 5px;
          text-align: center;
          width: 100%;
          &.black {
            color: $black;
          }

          input {
            margin-left: 9px;
            margin-top: 0;
          }
        }
        .height-value {
          display: block;
          color: $blue;
          &.black {
            color: $black;
          }
        }
        input {
          color: $blue;
          font-size: 18px;
          height: 30px;
          margin-top: 5px;
          text-align: center;
          width: 70px;
        }
      }
      .formula-result {
        align-items: center;
        display: flex;
        font-size: 18px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        margin-left: 12px;
        @include respond-to(mobile) {
          font-size: 21px;
        }
        .vis-hidden {
          opacity: 0;
          display: inline-block;
        }
        .calculate-htTKV-button {
          align-items: center;
          background-color: $dark-blue;
          border-radius: 5px;
          color: $white;
          cursor: pointer;
          display: flex;
          font-size: 16px;
          height: 37px;
          justify-content: center;
          width: 228px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold",
            "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
            "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          @include respond-to(mobile) {
            height: initial;
            width: initial;
            padding: 5px;
          }
        }
        .htTKV-value-container {
          border: 1px solid $blue;
          border-radius: 5px;
          display: inline-block;
          font-size: 14px;
          height: 90px;
          padding: 10px 15px 0;
          text-align: center;
          @include respond-to(mobile) {
            padding: 10px 10px 0;
          }
          .htTKV-title {
            margin-bottom: 13px;
          }
          .htTKV-value {
            color: $blue;
            font-size: 20px;
            font-family: "ApexSans-Bold", "Helvetica Neue Bold",
              "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
              "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          }
        }
      }
      .modal-shade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1;
        .converter-container {
          align-items: center;
          background-color: $light-gray;
          border-radius: 5px;
          display: flex;
          font-size: 14px;
          flex-direction: column;
          padding: 25px;
          text-align: center;
          width: 200px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          .prompt {
            width: inherit;
          }
          .close {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 16px;
            height: 16px;
            opacity: 0.3;
            cursor: pointer;
          }
          .close:hover {
            opacity: 1;
          }
          .close:before,
          .close:after {
            position: absolute;
            content: " ";
            left: 7px;
            height: 17px;
            width: 2px;
            background-color: $border-gray;
          }
          .close:before {
            transform: rotate(45deg);
          }
          .close:after {
            transform: rotate(-45deg);
          }
          input {
            color: $blue;
            font-size: 15px;
            height: 30px;
            margin: 5px 0 15px;
            text-align: center;
            width: 70px;
          }
        }
      }
    }
    #calculate-age-form {
      margin-bottom: 40px;
      .age-calculation-container {
        position: relative;
        text-align: center;
        .error-container {
          font-size: 13px;
          .error {
            color: $error;
            margin: 0 0 10px;
          }
        }
        .formula-container {
          margin-bottom: 10px;
        }
      }
      .flex {
        display: flex;
        align-items: center;
        margin-left: 200px;
        @include respond-to(mobile) {
          margin: 0;
          padding-top: 43px;
          position: relative;
          width: 100%;
        }
      }
      .steps {
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        margin-right: 12px;
        text-align: right;
        width: 100px;
        @include respond-to(mobile) {
          display: block;
          font-size: 16px;
          margin-right: 0;
          margin-bottom: 25px;
          position: absolute;
          text-align: center;
          top: 0;
          width: 100%;
          br {
            display: none;
          }
        }
      }
      .formula-container {
        align-items: center;
        background-color: $light-gray;
        border-radius: 5px;
        display: flex;
        font-size: 20px;
        flex-direction: column;
        height: 80px;
        justify-content: center;
        padding: 10px 20px;
        width: 140px;
        .age-value {
          display: block;
          color: $blue;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold",
            "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
            "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          &.black {
            color: $black;
          }
        }
        input {
          color: $blue;
          font-size: 20px;
          height: 30px;
          margin-top: 5px;
          text-align: center;
          width: 70px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold",
            "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
            "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        }
      }
      .plot-age-button {
        align-items: center;
        background-color: $dark-blue;
        border-radius: 5px;
        color: $white;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold",
          "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
          "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        height: 37px;
        justify-content: center;
        margin-left: 12px;
        width: 188px;
        @include respond-to(mobile) {
          height: initial;
        }
      }
    }

    .toggle-section {
      background-color: transparent;
      border: 0;
      width: 100%;
      padding: 0;
      border-radius: 0;
      cursor: pointer;
      display: block;
      margin: 0;
    }

    .instructions {
      background-color: $light-gray;
      border-radius: 5px;
      display: flex;
      font-size: 18px;
      padding: 30px 50px 30px 10px;
      position: relative;

      @include respond-to(desktop) {
        align-items: center;
      }

      sup {
        font-size: 10px;
        align-self: initial;
        vertical-align: baseline;
        position: relative;
        top: -0.8em;
      }
      .calculation-instructions {
        display: inline-flex;
        align-items: center;
        @include respond-to(mobile) {
          flex-wrap: wrap;
        }
        .pi-formula {
          align-items: center;
          display: inline-flex;
          flex-direction: column;
          font-size: 11px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold",
            "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
            "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          margin: 0 7px;
          @include respond-to(mobile) {
            vertical-align: top;
          }
          .pi-symbol {
            border-bottom: 2px solid $border-gray;
            padding: 0 5px;
            svg {
              width: 10px;
              height: 12px;
            }
          }
        }
      }
    }

    .step-number {
      background-color: $blue;
      border-radius: 50%;
      color: $white;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
        "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
        "Arial-Bold", Arial, sans-serif;
      height: 27px;
      width: 27px;
      margin-right: 10px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex: 0 0 auto;
    }

    .instructions:after {
      border-bottom: 20px solid $blue;
      border-left: 20px solid transparent;
      content: " ";
      height: 0;
      position: absolute;
      right: 20px;
      transform: rotate(45deg);
      width: 0;
      transition: transform 0.3s;
      transform-origin: 60% 60%;
    }
    .step.animated {
      animation-duration: 1300ms;
      .instructions {
        &:before {
          background-color: $blue;
          color: $white;
        }
      }
      &.open {
        .instructions {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &.arrow-rotated {
        .instructions {
          &:after {
            transform: rotate(-135deg);
          }
        }
      }
    }

    .jump-link-container {
      margin-top: 80px;
      text-align: center;

      button {
        background-color: transparent;
        cursor: pointer;
        border: 0;
        padding: 5px;

        &:hover,
        &:focus {
          .arrow {
            transform: translateY(3px);
          }
        }
      }

      .next-steps-link {
        color: $blue;
        cursor: pointer;
        text-decoration: none;
      }

      .arrow {
        border: solid transparent;
        border-top-color: $blue;
        border-width: 10px;
        content: " ";
        display: block;
        height: 0;
        margin: 10px auto 0;
        pointer-events: none;
        width: 0;
        transition: transform 0.3s;
      }
    }
  }
  #imaging-classification-chart {
    .flex {
      display: flex;
      @include respond-to(mobile) {
        flex-direction: column;
      }
    }
    .classification {
      font-size: 18px;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
        "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
        "Arial-Bold", Arial, sans-serif;
      text-align: center;
      margin: 40px 0 0;
    }
    .chart-container {
      position: relative;
      width: initial;
      margin-right: 10px;
      @include respond-to(mobile) {
        margin: 0;
      }
      p.image-title {
        text-align: center;
        margin-top: 0;
      }
      .imaging-chart {
        width: 600px;
        height: 318px;
        margin-right: 10px;
        @include respond-to(mobile) {
          width: 100%;
          height: auto;
          margin: 0;
        }
      }
      .point {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 100px;
        background-color: $white;
        border: 1px solid $black;
        z-index: 1;
        .dot {
          display: block;
          background-color: black;
          width: 8px;
          height: 8px;
          border-radius: 100px;
          margin: auto;
          position: absolute;
          top: 2px;
          left: 2px;
        }
      }
      .dotted-box {
        position: absolute;
        border-top: 3px dashed $white;
        border-right: 3px dashed $white;
        box-sizing: border-box;
      }
      .image-cover {
        opacity: 0.5;
        position: relative;
        &.clear {
          opacity: 1;
        }
        .vis-hidden,
        .chart {
          position: absolute;
        }
      }
    }
    .classification-container {
      width: 280px;
      @include respond-to(mobile) {
        margin-top: 40px;
      }
      p {
        margin-top: 0;
      }
      .classification-table {
        .flex {
          @include respond-to(mobile) {
            flex-direction: row;
            width: 100%;
          }
          &.header {
            align-items: flex-end;
            border-bottom: 1px solid $gray;
            margin-bottom: 5px;
          }
          &.header span {
            font-size: 10px;
            padding: 0 10px 5px 0;
          }
          &.row {
            border-radius: 5px;
            &.active {
              background-color: $active-class;
            }
          }
          &.row span {
            font-size: 12px;
            line-height: 36px;
            padding: 0 10px 0 0;
          }
          span:first-child {
            width: 33px;
            text-align: center;
            @include respond-to(mobile) {
            }
          }
          span:nth-child(2) {
            width: 115px;
            @include respond-to(mobile) {
            }
          }
          span:last-child {
            padding-right: 0;
            width: 133px;
            @include respond-to(mobile) {
            }
          }
        }
      }
    }
  }
  .restart-link {
    color: $blue;
    font-size: 17px;
    font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
      "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
      "Arial-Bold", Arial, sans-serif;
    float: right;
    margin: 0;
    text-decoration: none;
  }
}
