.container {
  position: relative;
  width: 100%;
  height: 79px;
  padding: 0;
  margin: 0;
  margin-top: 8px;
  .slider-label {
    font-size: 11px;
    margin: 0;
    text-align: center;
  }
}

.drag-container {
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0;
  margin: -24px 0 0 -1px;
  /* border: 1px dashed blue; */
  .slider-button {
    pointer-events: all;
    img {
      cursor: pointer;
    }
  }
}
