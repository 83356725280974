@import "../../../css/variables.scss";

.ue-modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  .modal-content {
    background: $white;
    padding: 60px 40px 30px 50px;
    @include respond-to(mobile) {
      width: 90%;
      padding: 50px 10px 30px;
    }
    .title-bar {
      font-size: 30px;
      margin: 0;
      @include respond-to(mobile) {
        font-size: 22px;
      }
    }
    .close-button {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 25px;
      background-color: #5facbb;
      font-weight: 900;
      font-size: 24px;
      border-radius: 16px;
      width: 32px;
      height: 32px;
      span {
        display: block;
        margin: 2px 0 0 8px;
      }
    }
    img {
      display: block;
      width: 100%;
      margin: 30px 0 0;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 33px;
      height: 33px;
      opacity: 0.3;
      cursor: pointer;
    }
    .close:hover {
      opacity: 1;
    }
    .close:before,
    .close:after {
      position: absolute;
      content: " ";
      left: 15px;
      height: 34px;
      width: 2px;
      background-color: $border-gray;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }
  }
}
