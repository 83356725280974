.patient {
  margin-top: 80px;
}

.patient-container {
  display: flex;
  flex: 1;
  margin-top: 12px;
  gap: 20px;

}

.patient-data {
  width: 350px;
}

.patient-timeline-container {
  margin-left: 20px;
  display: flex;
  flex: 1;
  width: 100%;
}

.footer-section div.disclaimer {
  font-size: 15px;
  margin: 45px 0;
  text-align: center;
}