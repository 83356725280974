$blue: #5ec0cd;
$light-blue: #dff2f5;
$gray: #b7b7b7;
$light-gray: #efefef;
$tabbed-gray: #c9c9c9;
$medium-gray: #707070;
$dark-gray: #4a4a4a;
$border-gray: #979797;
$border-gray-alt: #d8d8d8;
$header-gray: #4e5862;
$footer-gray: #cccccc;
$ukl-background-gray: #f1f1f1;
$white: #ffffff;
$black: #000000;
$dark-blue: #3a4651;
$purple: #8683b1;
$active-class: #a5c1d6;
$tour-yellow: #f5a623;

$green: #3ab44b;
$yellow: #fec166;
$orange: #f57e29;
$red: #be1e2e;

$score-gray: #f1f1f1;
$score-yellow: #f8b766;

$error: #e80808;

@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: 975px) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: 976px) {
      @content;
    }
  }
}
