@import "../../css/variables.scss";

h3>span.header {
  color: #4a4a4a;
  font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
    "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
  font-size: 20px;

  sup {
    font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
    font-weight: normal;
    font-size: 10px;
  }
}

.baseline ul {
  padding: 0;

  .reset-link {
    color: #5ec0cd;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
      "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
    margin-top: 20px;
  }
}

.baseline ul li {
  list-style: none;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.baseline ul li.alternate-axis {
  display: block;
  background-color: #d8d8d8;
  border-radius: 5px;
  color: #d8d8d8;
  padding: 20px;
  margin-top: 50px;

  &.revealed {
    background-color: #3a4651;

    .cKey,
    .cVal {
      color: #d8d8d8;
    }
  }

  p {
    font-size: 14px;
    font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
      "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
    margin: 0;

    a {
      color: #5ec0cd;
      display: inline-block;
      margin-top: 10px;
    }
  }

  .tour-container p {
    // Note: this fixes a bug caused by the font-size being set to 14px
    // above. Ideally the cascading rules and/or HTML structure could be better
    // modified to resolve this but a "quick fix" that minimizes side effects
    // is needed now.
    font-size: 15px;
  }

  .cKey,
  .cVal {
    color: #4a4a4a;
    width: 100%;
    display: block;
    padding: 0;
    position: relative;

    &.unavailable {
      font-weight: normal;
    }

    .classification {
      font-size: 18px;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
        "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
      margin: 0;
    }

    .description {
      margin-bottom: 20px;
    }

    .estimated-age {
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;

      span:last-of-type {
        font-size: 30px;
        margin-right: 10px;
      }
    }
  }

  .cKey {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
      "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
  }

  .mri-scan-container {
    display: flex;

    img {
      margin-right: 10px;
      width: 145px;
      height: 100px;

      @include respond-to(mobile) {
        width: 50%;
        height: 50%;
      }
    }

    img:last-of-type {
      margin: 0;
    }
  }
}

.baseline ul li.with-subsections {
  flex-direction: column;
  align-items: flex-start;
}

.with-subsections .section {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.with-subsections .section .row {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.title-key {
  font-size: 14px;
  color: black;
  font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
    "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
  margin: 30px 0 5px;
}

.cKey {
  font-size: 14px;
  color: #4a4a4a;
  width: 56%;
}

.cVal {
  font-size: 14px;
  font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
    "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
  padding: 5px;
  flex: 1;

  .revealer {
    color: #5ec0cd;
    cursor: pointer;
  }
}

.expand-icon {
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
}

.cVal span:nth-child(3) {
  color: #5ec0cd;
}

.cVal span span {
  font-weight: normal;
  font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
}

.superscript {
  font-size: 0.5rem;
}

.cVal .expand-button-arrows {
  margin: 0 0 0 2px;
  border: 1px solid black;
  background-color: white;
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 1px;
}

.profile-acronyms {
  font-size: 12px;
  color: #384553;
  padding: 20px 0 20px 0;

}

// $step-0: clamp(0.63rem, calc(0.48rem + 0.57vw), 1.13rem);
// $step-1: clamp(0.75rem, calc(0.58rem + 0.69vw), 1.35rem);
// $step-2: clamp(0.9rem, calc(0.69rem + 0.82vw), 1.62rem);
// $step-3: clamp(1.08rem, calc(0.83rem + 0.99vw), 1.94rem);
// $step-4: clamp(1.3rem, calc(1rem + 1.18vw), 2.33rem);
// $step-5: clamp(1.56rem, calc(1.2rem + 1.42vw), 2.8rem);
// $step-6: clamp(1.87rem, calc(1.44rem + 1.71vw), 3.36rem);

table[class="adpkd-patient-overview"] {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;

  small {
    font-size: 10px;
    font-weight: 300;
  }

  p {
    margin: 0;
    padding: 3px 5px;
  }

  &> {
    vertical-align: middle;
  }

  td,
  th {
    padding: 0 2rem;
  }

  td {
    text-align: center;
  }

  thead {
    th {
      &[scope="col"] {
        color: #4a4a4a;
        text-align: start;
        font-size: 10px;
        text-transform: uppercase;
        text-align: center;
      }

      &[scope="row"] {}

      // &:nth-child(1) {
      //   width: 33%;
      // }
      // &:nth-child(2) {
      //   width: 33%;
      // }
      // &:nth-child(3) {
      //   width: 33%;
      // }
    }
  }

  tbody {
    th[scope="col"] {
      color: #4a4a4a;
    }

    th[scope="row"] {
      color: #4a4a4a;
      text-align: start;
    }

    td {
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
        "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }

  th,
  td {
    padding: 5px;
  }
}