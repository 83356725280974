@import "../../../css/variables.scss";

.tour-container {
  position: relative;
  display: inline;

  .tour-shade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
  }

  &.closed {
    position: relative;
    display: inline;

    .tour-step {
      cursor: pointer;
      height: 30px;
      width: 40px;
      z-index: 10000;
      position: absolute;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
      left: 10px;
      top: -5px;
    }

    .tour-shade {
      display: none;
    }
  }

  .tour-step {
    &.open {
      min-width: 180px;
      width: clamp(600px, 800px, 45vw);

      // tour step 0 position:
      &.initial {
        width: 30vw;
        top: 300px;
        left: 50%;
        transform: translateX(-50%);
        padding: 50px 20px;
        text-align: center;
        font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
          "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        position: fixed;

        @include respond-to(mobile) {
          width: 80%;
          max-width: initial;
          min-width: initial;
          padding: 50px 20px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        p {
          font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        }
      }

      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      left: 30px;
      top: -20px;
      position: absolute;
      background-color: $tour-yellow;
      border-radius: 5px;
      color: $white;
      font-size: 15px;
      z-index: 10005;

      @include respond-to(mobile) {
        position: fixed;
        width: 80%;
        top: 20%;
      }

      &.current-step:after {
        right: 100%;
        top: 0%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-right-color: $tour-yellow;
        margin-top: 18px;
        border-top-width: 10px;
        border-right-width: 20px;
        border-bottom-width: 10px;
        border-left-width: 0;

        @include respond-to(mobile) {
          display: none;
        }
      }

      .close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }

      .close:before,
      .close:after {
        position: absolute;
        content: " ";
        left: 7px;
        height: 17px;
        width: 2px;
        background-color: $white;
      }

      .close:before {
        transform: rotate(45deg);
      }

      .close:after {
        transform: rotate(-45deg);
      }

      .contents {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        figure.tour-figures {
          margin: 0;
          width: 100%;
          height: 100%;

          padding: 20px;
          box-sizing: border-box;

          img {
            width: 100%;
            object-fit: contain;
            max-height: 45vh;
          }
        }

        .step-number {
          color: $white;
          font-size: 20px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          margin: 5px;
          display: inline-block;
        }

        h4 {
          margin: 0;
          padding: 10px 30px 5px;
          font-size: 18px;
          text-align: left;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
        }

        p {
          margin: 0;
          padding: 10px 30px 25px;
          font-size: 15px;
          line-height: 1.2;

          &+p {
            // This fixes an issue caused by the incorrect assumption
            // that flags would only have one paragraph. A better long
            // term solution would be to control the inner padding at
            // the .contents div rather than at the <p>, however a quick
            // fix that minimizes side effects has to be put into place
            // at this time.
            margin-top: -15px;
          }

          font-family: "ApexSans-Medium",
          "Helvetica Neue Medium",
          "HelveticaNeue-Medium",
          "Helvetica Neue",
          Roboto,
          "Roboto Medium",
          "Roboto-Medium",
          "Arial Medium",
          "Arial-Medium",
          "Arial Bold",
          "Arial-Bold",
          Arial,
          sans-serif;

          sup {
            font-size: 10px;
          }
        }

        .next-button-container {
          display: flex;
          flex-direction: row;
        }

        .next-button {
          align-self: flex-end;
          display: block;
          color: $white;
          font-size: 12px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
            "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          padding: 10px 15px;
          cursor: pointer;
          // float: right;
        }
      }
    }
  }
}

// .zooming-charts-container {
//   .tour-container {
//     &.closed {
//       .tour-step {
//         left: initial;
//         top: 0;
//         right: 25px;
//         @include respond-to(mobile) {
//           top: 50%;
//           right: 0px;
//         }
//       }
//     }
//     .tour-step {
//       &.open {
//         left: -252px;
//         top: -224px;
//         &.current-step:after {
//           display: none;
//         }
//         @include respond-to(mobile) {
//           transform: translate(-14%, 0%);
//         }
//       }
//     }
//   }
// }

// .alternate-axis {
//   .tour-container {
//     position: absolute;
//     top: 20px;
//     right: 0;
//     @include respond-to(mobile) {
//       .tour-step {
//         &.open {
//           transform: translate(-94%, 0%);
//         }
//       }
//     }
//   }
//   .revealer-container {
//     .tour-container {
//       position: absolute;
//       top: 0px;
//       right: 0;
//     }
//   }
// }

// .drag-container {
//   pointer-events: none;
//   ~ .tour-container {
//     display: block;
//     &.closed {
//       top: 100%;
//       left: 46%;
//       position: absolute;
//       .tour-step {
//         height: 40px;
//         width: 32px;
//       }
//     }
//     .tour-step {
//       &.open {
//         top: 53px;
//         left: initial;
//         transform: translate(50%, 0%);
//         right: 50%;
//         width: 90%;
//         max-width: 90%;
//         &.current-step:after {
//           bottom: 100%;
//           left: 50%;
//           top: initial;
//           right: initial;
//           border-bottom-color: $tour-yellow;
//           border-right-color: transparent;
//           margin-left: -6px;
//           border-top-width: 0;
//           border-right-width: 10px;
//           border-bottom-width: 20px;
//           border-left-width: 10px;
//           @include respond-to(mobile) {
//             display: block;
//             margin-left: 0;
//           }
//         }
//       }
//     }
//   }
// }