@import "./variables.scss";

.simulator-homepage {
  margin: 0 auto;
  width: 938px;
  box-sizing: border-box;
  @include respond-to(mobile) {
    width: 100%;
    padding: 0 15px;
  }
  .page-crumb {
    display: none;
    @include respond-to(mobile) {
      display: block;
      font-size: 13px;
      margin-top: 15px;
    }
  }
  .title {
    font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
      "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
      "Arial-Bold", Arial, sans-serif;
    font-size: 40px;
    margin: 80px 0 60px;
    text-align: center;
    @include respond-to(mobile) {
      font-size: 30px;
      margin: 20px 0 30px;
      text-align: left;
    }
  }
  .select-patient-container,
  .flex-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    @include respond-to(mobile) {
      flex-direction: column;
      margin-bottom: 30px;
    }
    svg {
      width: 120px;
      height: 90px;
      margin-right: 30px;
      @include respond-to(mobile) {
        display: none;
      }
    }
    p {
      font-size: 18px;
      margin: 10px 0 0;
      @include respond-to(mobile) {
        margin: 0;
      }
    }
  }
  .instruction-row {
    position: absolute;
    @include respond-to(mobile) {
      position: relative;
    }
  }
  .select-patient-container {
    align-items: center;
    display: block;
    position: absolute;
    top: 18px;
    left: -10px;
    width: 154px;
    padding-right: 60px;
    background-image: url("../images/triangle-background.svg");
    background-repeat: no-repeat;
    background-position: right center;

    @include respond-to(mobile) {
      display: inline-block;
      margin-bottom: 40px;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      background: none;
    }
    .instruction-title {
      font-size: 24px;
      font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
      text-align: right;
      @include respond-to(mobile) {
        text-align: left;
        width: 100%;
      }
    }
  }
  .link-reset {
    align-items: center;
    box-sizing: border-box;
    color: inherit;
    padding: 0 7px;
    text-align: center;
    text-decoration: none;
    width: 100%;
    @include respond-to(mobile) {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      padding: 0;
      text-align: left;
      width: 100%;
      .profile-data {
        margin-left: 15px;
        width: 75%;
      }
    }
    img {
      background-color: $blue;
      border: 4px solid $blue;
      border-radius: 50%;
      width: 100%;
      margin-left: -4px;
      margin-bottom: -4px;
      @include respond-to(mobile) {
        align-self: flex-start;
        width: 25%;
        min-width: 100px;
        margin: 0;
      }
    }
    div.text-link {
      font-size: 22px;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
        "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
        "Arial-Bold", Arial, sans-serif;
      margin: 20px 0;
      @include respond-to(mobile) {
        margin: 0 0 10px;
      }
    }
    .profile-data {
      .risk-factors {
        display: none;
        @include respond-to(mobile) {
          display: block;
        }
        h3 {
          border-bottom: 1px solid $border-gray-alt;
          font-size: 14px;
          font-family: "ApexSans-Bold", "Helvetica Neue Bold",
            "HelveticaNeue-Bold", "Helvetica Neue", Roboto, "Roboto Bold",
            "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
          line-height: 15px;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
        p {
          font-size: 12px;
          margin: 7px 0;
        }
      }
    }
    p.title {
      font-size: 13px;
      color: $dark-blue;
      margin: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid $tabbed-gray;
      @include respond-to(mobile) {
        border-bottom: none;
        font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial,
          sans-serif;
      }
    }
    p.description {
      font-size: 16px;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
        "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
        "Arial-Bold", Arial, sans-serif;
      color: $dark-blue;
      width: 170px;
      @include respond-to(mobile) {
        width: 100%;
      }
    }
  }
  .typical-container {
    border: 1px solid $blue;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    @include respond-to(mobile) {
      flex-direction: column;
      align-items: left;
      padding: 30px 15px;
    }
    .link-text {
      color: $blue;
      font-size: 15px;
      font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold",
        "Helvetica Neue", Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold",
        "Arial-Bold", Arial, sans-serif;
      width: 70%;
      @include respond-to(mobile) {
        margin-bottom: 10px;
        width: 100%;
      }
    }
    sup {
      font-size: 9px;
    }
  }
  .patient-table {
    @include respond-to(mobile) {
      .risk-factors-table {
        display: none;
      }
    }
    .row {
      border-top: 1px solid $border-gray-alt;
      @include respond-to(desktop) {
        display: flex;
      }
      &.borderless {
        border: none;
        @include respond-to(mobile) {
          .col:first-of-type {
            display: none;
          }
        }
      }
      &.header {
        align-items: flex-end;
        @include respond-to(mobile) {
          align-items: flex-start;
          flex-direction: column;
        }
        strong {
          margin: 10px 0;
        }
        .col:nth-child(2n) {
          background: linear-gradient(#ffffff, #ffffff 75%, #eeeeee);
          @include respond-to(mobile) {
            background: none;
          }
        }
      }
      &:last-of-type {
        border-bottom: 1px solid $border-gray-alt;
      }
      .col {
        align-items: center;
        display: flex;
        width: 15%;
        &:first-of-type {
          @include respond-to(desktop) {
            justify-content: flex-start;
            width: 25%;
          }
        }
        &:nth-child(n + 2) {
          @include respond-to(desktop) {
            justify-content: center;
          }
        }
        &:nth-child(2n) {
          background-color: #eeeeee;
        }
        @include respond-to(mobile) {
          width: 100%;
        }
        .risk-factor {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          padding: 15px 0;

          .icon {
            width: 25px;
            margin-right: 15px;
            flex: 0 0 auto;

            img {
              max-width: 100%;
              height: auto;
              display: block;
              margin: 0 auto;
              width: auto;
            }
          }
          p {
            font-size: 14px;
            margin: 0;
          }
        }
        .circle {
          background-color: black;
          border-radius: 50%;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .footer-section {
    margin: 40px 0 10px;
    font-size: 11px;
    @include respond-to(mobile) {
      margin: 30px 0 10px;
    }
    .glossary {
      margin-bottom: 10px;
    }
    .references {
      font-size: 11px;
    }
  }
}
