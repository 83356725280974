@import "../css/variables.scss";

dfn {
    font-weight: bold;
    padding: 0 5px;
}

.dfn-block {
    font-size: small;
    text-align: center;
    padding: 1rem;
    background-color: $light-blue;
    color: $dark-blue;
}
