.kidney-image-container {
  width: 524px;
  height: 266px;
  border: 2px solid #3a4651;
  /* border-right: 1px; */
}

.kidney-image {
  position: absolute;
}

.hidden {
  display: none;
}

/* .kidney-sliders-container {
  margin-top: 25px;
  height: 266px;
  width: 90px;
  border: 2px solid #3a4651;
  border-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.kidney-overlay-container {
  display: flex;
  flex-direction: row;
  position: relative;
} */