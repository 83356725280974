@import "../../../css/variables.scss";

.ic-modal {
  overflow-y: scroll;
  max-height: 80vh;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  .modal-content {
    background: $white;
    padding: 60px 40px 30px 50px;
    @include respond-to(mobile) {
      padding: 50px 10px 30px;
    }
    .title-bar {
      font-size: 30px;
      margin: 0;
      @include respond-to(mobile) {
        font-size: 22px;
      }
    }
    img {
      display: block;
      margin-top: 30px;
      width: 98%;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 33px;
      height: 33px;
      opacity: 0.3;
      cursor: pointer;
    }
    .close:hover {
      opacity: 1;
    }
    .close:before,
    .close:after {
      position: absolute;
      content: " ";
      left: 15px;
      height: 34px;
      width: 2px;
      background-color: $border-gray;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }
  }
}
