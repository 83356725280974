@import "./variables.scss";

.prognostic-tools-indexer {
  margin: 0 auto;
  width: 938px;
  .breadcrumbs {
    display: none;
    @include respond-to(mobile) {
      display: flex;
      font-size: 13px;
      margin-top: 15px;
      margin-left: 10px;
      letter-spacing: -0.3px;
      .link-text {
        color: $blue;
        text-decoration: none;
      }
      .page-crumb {
        margin: 0;
        span {
          margin: 0 5px;
        }
      }
    }
  }
  @include respond-to(mobile) {
    width: 100%;
  }
  .tabbed-section-container {
    margin-bottom: 100px;
    @include respond-to(mobile) {
      display: none;
    }
    .tabbed-top {
      .tab-nav {
        .tab {
          // width: 230px;
          padding: 0 4px;
        }
      }
    }
  }
}

.prognostic-tools {
  margin: 0 auto;
  width: 938px;
  .page-crumb {
    display: none;
    @include respond-to(mobile) {
      display: block;
      font-size: 13px;
      margin: 0;
      margin-top: 15px;
      margin-left: 10px;
    }
  }
  @include respond-to(mobile) {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .title {
    font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
    font-size: 40px;
    margin: 80px 0 30px;
    text-align: center;
    @include respond-to(mobile) {
      font-size: 30px;
      margin: 30px 0;
      text-align: left;
    }
  }
  .flex-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    @include respond-to(mobile) {
      flex-direction: column;
      align-items: center;
    }
    img {
      height: 284px;
      margin-right: 30px;
      @include respond-to(mobile) {
        margin: 0;
      }
    }
    div.text-link {
      font-size: 28px;
      font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
      @include respond-to(mobile) {
        font-size: 24px;
      }
    }
    p {
      font-size: 18px;
      margin: 10px 0 20px;
      @include respond-to(mobile) {
        font-size: 15px;
      }
    }
    .link-reset {
      color: inherit;
      text-decoration: none;
      width: 225px;
      &.nav-button {
        background-color: $dark-blue;
        border-radius: 5px;
        color: $white;
        display: block;
        font-size: 16px;
        font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
        padding: 10px;
        text-align: center;
      }
    }
  }
  .section-title {
    font-size: 24px;
    font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
    text-align: center;
    margin-bottom: 8px;
  }
  .section-subtitle {
    font-size: 16px;
    text-align: center;
    margin: 0 0 40px 0;
    font-weight: 700;
  }
  .methods-section {
    display: flex;
    align-items: flex-start;
    margin-bottom: 80px;
    @include respond-to(mobile) {
      flex-direction: column;
      margin-bottom: 40px;
    }
    .method-container {
      padding: 0 17px;
      width: 33%;
      @include respond-to(mobile) {
        border-right: none;
        height: inherit;
        margin-bottom: 30px;
        padding: 0;
        width: 100%;
      }
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        border-right: none;
      }
      img {
        width: 97px;
        height: 97px;
        margin: 0 auto 20px;
        display: block;
      }
      .link-reset {
        background-color: $blue;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        height: 58px;
        min-height: 58px;
        text-decoration: none;
        text-align: center;
        align-items: center;
        justify-content: center;
        @include respond-to(mobile) {
          margin: 0;
        }
        &:hover {
          text-decoration: underline;
          text-decoration-color: $white;
        }
        .link-text {
          color: $white;
          font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
          margin: 0;
          .arrow {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 4px;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 7px solid $white;
          }
        }
        @include respond-to(mobile) {
          .text-link {
            margin-top: 30px;
          }
          .description {
            margin-bottom: 0;
          }
        }
      }

      .risk-factors {
        background-color: #dff2f5;
        padding: 15px 20px;
        margin-top: 20px;

        .title {
          font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
          font-size: 15px;
          margin: 0;
          text-align: left;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 20px 0 0;

          li {
            display: flex;
            align-items: flex-start;

            &:not(:first-child) {
              margin-top: 20px;
            }

            p {
              font-size: 15px;
              margin: 2px 0 0;
            }

            .icon {
              width: 25px;
              margin-right: 5px;
              flex: 0 0 auto;

              img {
                max-width: 100%;
                height: auto;
                display: block;
                margin: 0 auto;
                width: auto;
              }
            }
          }
        }
      }

      .description {
        margin: 0;
        @include respond-to(mobile) {
          margin: 0 0 40px;
        }
        .title {
          font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
          font-size: 15px;
          text-align: left;
          margin: 1em 0 0;
        }
        p {
          font-size: 15px;
          margin-top: 0;
          .link-text {
            color: $blue;
            font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
          }
        }
      }
    }
  }
  .factors-section {
    display: flex;
    align-items: flex-start;
    margin-bottom: 100px;
    @include respond-to(mobile) {
      flex-direction: column;
      margin-bottom: 40px;
    }
    .factors-container {
      width: 50%;
      @include respond-to(mobile) {
        width: 100%;
      }
      &:first-of-type {
        margin-right: 60px;
        @include respond-to(mobile) {
          margin-right: 0;
          .risk-factor:last-of-type {
            border-bottom: none;
          }
        }
      }
      .risk-factor {
        align-items: center;
        border-top: 1px solid $border-gray-alt;
        box-sizing: border-box;
        display: flex;
        height: 60px;
        padding: 15px 10px;
        &:last-of-type {
          border-bottom: 1px solid $border-gray-alt;
        }
        p {
          margin: 0 0 0 15px;
        }
      }
    }
  }
  .footnote,
  .glossary {
    font-size: 12px;
  }
  .footer-section {
    font-size: 11px;
    .glossary {
      margin: 0 0 30px 0;
    }
    div.disclaimer{
      font-size: 15px;
      margin: 45px 0;
      text-align: center;
    }
  }
}

.era-edta-algorithm {
  .title {
    font-size: 36px;
    font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
  }
  @include respond-to(mobile) {
    padding: 15px;
  }
  .flowchart-container {
    margin-top: 50px;
    img {
      width: 100%;
      @include respond-to(mobile) {
        display: none;
      }
    }
    .algorithm-flowchart {
      display: none;
      @include respond-to(mobile) {
        display: block;
        .card {
          .header {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background-color: $dark-gray;
            color: $white;
            font-size: 15px;
            font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
            padding: 15px 0;
            text-align: center;
          }
          .data {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: $ukl-background-gray;
            color: $dark-gray;
            padding: 20px 15px 15px;
            p {
              font-size: 12px;
              margin: 0;
            }
          }
        }
        .flow-container {
          color: $white;
          display: flex;
          justify-content: space-evenly;
          font-size: 12px;
          font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
          div {
            border-radius: 5px;
            padding: 10px;
            text-align: center;
            position: relative;
            margin: 15px 0 20px;
            display: flex;
            align-items: center;
            &:nth-child(2) {
              margin-left: 10px;
            }
            &:nth-child(3) {
              margin-left: 10px;
            }
          }
          .stop {
            &:before {
              content: "";
              width: 0;
              height: 12px;
              position: absolute;
              border: 1px solid $dark-gray;
              top: -14px;
              left: 50%;
            }
            &.green {
              background-color: $green;
            }
            &.yellow {
              background-color: $yellow;
            }
            &.orange {
              background-color: $orange;
            }
            &.red {
              background-color: $red;
            }
          }
          .flow {
            background-color: $border-gray;
            .arrow {
              bottom: -20px;
              left: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-top-color: $dark-gray;
              border-top-width: 12px;
              border-right-width: 5px;
              border-bottom-width: 0px;
              border-left-width: 5px;
              margin-left: -4px;
            }
            &:before,
            &:after {
              content: "";
              width: 0;
              height: 12px;
              position: absolute;
              border: 1px solid $dark-gray;
              left: 50%;
            }
            &:before {
              top: -14px;
            }
            &:after {
              bottom: -14px;
            }
          }
        }
      }
    }
  }
  .footer-section {
    font-size: 11px;
    margin: 30px 0;
    .adapted-container {
      font-size: 14px;
    }
    .limitation-container {
      border: 1px solid $border-gray-alt;
      border-radius: 5px;
      font-size: 14px;
      margin: 30px 0;
      padding: 20px 30px;
      ul {
        margin: 0;
        padding: 0 15px;
        sup {
          font-size: 9px;
        }
      }
    }
    div.disclaimer{
      font-size: 15px;
      margin: 45px 0;
      text-align: center;
    }
  }
  .glossary,
  .references {
    font-size: 11px;
  }
  .references {
    margin-bottom: 50px;
  }
}

.ultrasound-kidney-length {
  font-size: 16px;
  @include respond-to(mobile) {
    margin: 0 10px;
  }
  strong {
    sup {
      font-size: 9px;
    }
  }
  .title {
    font-size: 36px;
    font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
  }
  .image-block {
    background-color: $ukl-background-gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 30px 50px 30px 20px;
    @include respond-to(mobile) {
      flex-direction: column;
      padding: 20px;
    }
    .title {
      font-size: 26px;
      line-height: 30px;
      margin: 0;
      font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
      span {
        font-size: 16px;
        font-weight: normal;
        font-family: "ApexSans-Book", 'Helvetica Neue', Roboto, Arial, sans-serif;
      }
    }
    .footnote {
      font-size: 11px;
    }
    img {
      flex-shrink: 0;
      margin-left: 60px;
      width: 250px;
      @include respond-to(mobile) {
        margin: 20px 0 0;
      }
    }
  }
  .limitation-container {
    border: 1px solid $border-gray-alt;
    border-radius: 5px;
    font-size: 13px;
    margin: 30px 0;
    padding: 20px 30px;
    ul {
      margin: 0;
      padding: 0 15px;
      sup {
        font-size: 9px;
      }
    }
  }
  .footnote,
  .glossary,
  .references {
    font-size: 11px;
  }
}

.propkd-score {
  @include respond-to(mobile) {
    margin: 0 15px;
  }
  .title {
    font-size: 36px;
    font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
    sup {
      font-size: 12px;
    }
  }
  .calculator-title {
    font-size: 22px;
    font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
    margin: 45px 0 10px;
    @include respond-to(mobile) {
      display: none;
    }
  }
  .img-container {
    img.total-points {
      width: 100%;
      @include respond-to(mobile) {
        display: none;
      }
    }
    .propkd-score-tables {
      display: flex;
      @include respond-to(mobile) {
        flex-direction: column;
      }
      margin: 28px 16px;
      .score-table {
        flex: 4;
        .row {
          display: flex;
          &.header {
            border-bottom: 4px solid $dark-blue;
            font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
            font-size: 22px;
          }
          &.indent {
            .col:first-of-type {
              padding-left: 40px;
            }
          }
          &.result {
            border-top: 4px solid $dark-blue;
            .col {
              border-left: none;
              font-size: 22px;
              font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
              &:first-of-type {
                border-left: 1px solid $dark-blue;
                padding: 0 10px;
                border-right: none;
                text-align: right;
                justify-content: flex-end;
              }
            }
          }
          .col {
            box-sizing: border-box;
            border: 1px solid $dark-blue;
            text-align: center;
            padding: 2px 20px;
            width: 10%;
            min-width: 20%;
            font-size: 16px;
            align-items: center;
            display: flex;
            justify-content: center;
            &:first-of-type {
              text-align: left;
              width: 80%;
              justify-content: flex-start;
            }
            &.link {
              color: $blue;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
      .example-calc {
        flex: 3;
        font-size: 14px;
        background-color: $score-gray;
        border-radius: 5px;
        padding: 25px;
        position: relative;
        margin-left: 30px;
        @include respond-to(mobile) {
          margin: 28px 0;
        }
        &:after {
          left: 0%;
          bottom: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-right-color: $score-gray;
          border-width: 20px;
          margin-left: -40px;

          @include respond-to(mobile) {
            margin-top: 30px 0;
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: $score-gray;
            border-width: 20px;
            margin-left: -20px;
          }
        }
        .title {
          font-size: 20px;
          margin: 0;
          padding: 0 15px;
        }
        .description {
          margin: 0;
          padding: 0 15px;
        }
        .point-container {
          margin-top: 20px;
          p {
            margin: 0;
            padding: 0 15px;
          }
          .total {
            &:before {
              content: "+";
              position: absolute;
              // left: 28px;
              transform: translateX(-100%);
            }
          }
          hr {
            margin: 5px 0;
          }
        }
        .classification {
          padding: 0 15px;
          margin-bottom: 0;
          .red {
            color: $red;
            font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
          }
        }
      }
      .score-table-classification {
        display: none;
        @include respond-to(mobile) {
          display: flex;
        }
        flex-direction: column;
        .row {
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
          &.header {
            background-color: $score-gray;
            .col {
              color: $black;
              justify-content: center;
              text-align: center;
              font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
              &:first-of-type {
                box-sizing: border-box;
                border-right: 2px solid $white;
                width: 25%;
                max-width: 100px;
                padding: 15px 0;
              }
            }
          }
          &.yellow {
            background-color: $score-yellow;
          }
          &.orange {
            background-color: $orange;
          }
          &.red {
            background-color: $red;
          }
          .col {
            display: flex;
            color: $white;
            flex-direction: column;
            border-bottom: 2px solid $white;
            width: 100%;
            &:first-of-type {
              width: 25%;
              max-width: 100px;
            }
            &:last-of-type {
              padding-left: 20px;
            }
            &.content {
              display: inline-block;
              padding-left: 20px;
              position: relative;
            }
            .row {
              border-bottom: 2px solid $white;
              border-right: 2px solid $white;
              height: 70px;
              align-items: center;
              justify-content: center;
              font-size: 22px;
              font-family: "ApexSans-Bold", 'Helvetica Neue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Roboto, 'Roboto Bold' ,'Roboto-Bold', 'Arial Bold', 'Arial-Bold', Arial, sans-serif;
              &:last-of-type {
                border-bottom: none;
              }
            }
            .title {
              font-size: 22px;
              margin: 15px 0 10px;
            }
            .subtitle {
              font-size: 20px;
              margin: 0;
            }
            .description {
              font-size: 20px;
              margin: 0;
            }
            .footnote {
              margin: 0;
              position: absolute;
              bottom: 10px;
            }
          }
        }
      }
    }
  }
  .footer-section {
    font-size: 11px;
    margin: 30px 0;
    .adapted-container {
      font-size: 14px;
    }
    .limitation-container {
      border: 1px solid $border-gray-alt;
      border-radius: 5px;
      font-size: 14px;
      margin: 30px 0;
      padding: 20px 30px;
      ul {
        margin: 0;
        padding: 0 15px;
        sup {
          font-size: 9px;
        }
      }
    }
    div.disclaimer{
      font-size: 15px;
      margin: 45px 0;
      text-align: center;
    }
  }
}
