h3.progression {
  margin-bottom: 22px;

  .header {
    sup {
      font-weight: normal;
      font-size: 10px;
    }
  }
}

.flip-deck-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.patient-slider {
  width: 575px;
}

.patient-timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.patient-timeline .viz {
  display: flex;
  flex-direction: column;
  // margin-left: 25px;
  // width: 530px;
}

.patient-timeline h3 {
  text-align: center;
}

.patient-timeline img.legend {
  position: absolute;
  top: 10px;
  right: 0;
  width: 120px;
}

div.revealer-container {
  margin-top: 20px;
  position: relative;
}

button.revealer {
  background-color: #5ec0cd;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  padding: 11px 10px 12px;
  text-decoration: none;
  max-height: 42px;
  margin: -5px auto 0 auto;
  width: 178px;
}

div.revealer>button:active {}