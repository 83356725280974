ul,
ol {
    padding-top: 5px;
    padding-bottom: 5px;
}

li {
    padding-top: 5px;
    padding-bottom: 5px;
}
