@import "../css/variables.scss";

.patient-adpkd-homepage {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @include respond-to(mobile) {
        padding: 0 20px;
    }
    .flex {
        display: flex;
        margin: 90px auto;
        width: 938px;
        align-items: flex-start;
        @include respond-to(mobile) {
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
        .main-image {
            margin-right: 50px;
            @include respond-to(mobile) {
                width: 125px;
                margin: 20px auto 0;
            }
        }
        .title {
            font-size: 36px;
            @include respond-to(mobile) {
                font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                    "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
                font-size: 28px;
                text-align: center;
            }
        }
        .sub-header {
            font-size: 24px;
            @include respond-to(mobile) {
                font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
                font-size: 18px;
                text-align: center;
            }
        }
        .cards-container {
            display: flex;
            align-items: stretch;
            flex-direction: row;
            justify-content: center;
            gap: 2rem;

            &:hover {
                cursor: pointer;
            }

            @include respond-to(mobile) {
                flex-direction: column;
                align-items: stretch;
            }
            .link-reset {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                justify-content: center;
                flex: 1;

                color: inherit;
                text-decoration: none;

                &:hover {
                    .link-text {
                        text-decoration: underline;
                    }
                }
                .patient-card {
                    // &:hover {
                    //     filter: drop-shadow(16px 16px 20px $blue) invert(15%);
                    //     cursor: pointer;
                    // }
                    height: 100%;
                    width: 100%;
                    display: flex;
                    padding: 30px 10px 0px 10px;
                    flex-direction: row;
                    align-items: stretch;
                    border-radius: 5px;
                    background-color: $blue;
                    color: $white;

                    .icon {
                        min-width: 40px;
                        display: flex;
                        flex-direction: column;
                    }

                    .information {
                        padding: 0 15px;
                        display: flex;
                        flex-direction: column;

                        .body {
                            flex: 1;
                        }
                        .link-to {
                        }
                    }

                    svg {
                        width: 100%;

                        path {
                            fill: $dark-blue;
                        }
                    }

                    span {
                        display: inherit;
                        font-size: 14px;
                        text-transform: uppercase;
                        &.link-text {
                            color: $dark-blue;
                            font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue",
                                Roboto, "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
                            margin-top: 25px;
                            margin-bottom: 15px;
                            .arrow {
                                display: inline-block;
                                width: 0;
                                height: 0;
                                margin-left: 4px;
                                border-top: 5px solid transparent;
                                border-bottom: 5px solid transparent;
                                border-left: 7px solid $dark-blue;
                            }
                        }
                    }
                    h2 {
                        font-size: 19px;
                        margin: 0;
                        line-height: 25px;
                        text-transform: uppercase;
                    }
                    p {
                        font-size: 15px;
                        margin: 0;
                    }
                }
            }
        }
    }
    .banner {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $light-gray;
        padding: 40px 0;
        width: 100%;
        @include respond-to(mobile) {
            margin: 60px 0 0;
            padding: 30px 20px;
        }
        svg {
            margin-bottom: 10px;
        }
        p {
            font-size: 18px;
            margin: 0;
            text-align: center;
            max-width: 940px;
        }
    }
    .disclaimer {
        font-size: 15px;
        max-width: 740px;
        margin: 45px 0;
        text-align: center;
    }
    &.not-found {
        .title {
            font-size: 42px;
            text-transform: uppercase;
            @include respond-to(mobile) {
                font-family: "ApexSans-Bold", "Helvetica Neue Bold", "HelveticaNeue-Bold", "Helvetica Neue", Roboto,
                    "Roboto Bold", "Roboto-Bold", "Arial Bold", "Arial-Bold", Arial, sans-serif;
                font-size: 28px;
                text-align: center;
            }
        }
        p {
            font-size: 18px;
            font-family: "ApexSans-Book", "Helvetica Neue", Roboto, Arial, sans-serif;
        }
    }
}
