@import "../../../../css/variables.scss";

.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  // &:nth-child(1) {
  //   margin-right: 10px;
  // }

  .chart-body {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .chart-headers {
    padding: 0 1rem;
    flex: 1;
    // min-height: 80px;

    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      border-bottom: 1px solid grey;
    }
  }

  .chart-secondary-header {
    small {
      font-size: x-small;
    }
  }

  .chart-main-header {
    font-size: 17px;
    // margin-left: 27px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }

  .chart-title {
    font-size: 18px;
    flex-grow: 1;
    margin-right: 2px;

    span.data {
      font-size: 14px;
    }

    span.measurement {
      font-size: 11px;
    }


  }
}

.chart-expander-button {
  background-color: white;
  height: 100%;
  cursor: pointer;
  padding: 5px 5px 5px 5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@include respond-to(mobile) {
  .modal-container .modal-center {
    display: block;
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    width: 90%;
    height: initial;
    z-index: 10002;
  }
}